import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useRef } from "react";
const isMobile = window.innerWidth <= 768;

const Editor = ({
  value,
  onChange,
}) => {
  const editorRef = useRef();
  const editorConfiguration = {
    height: 600,
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "insertTable",
      "imageUpload",
      "undo",
      "redo",
    ],
  };

  const b64toBlob = async (b64Data) => {
    const imagedata = await fetch(b64Data);
    const res = await imagedata.blob();
    return res;
  };
  return (
    <div
      style={{
        width: isMobile ? "95%" : "100%", 
      }}
    >
    <CKEditor
      editor={ClassicEditor}
      config={editorConfiguration}
      data={value || ""}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
            writer.setStyle(
              "background-color",
              "rgb(39, 39, 39)", // Dark background for the editable content
              editor.editing.view.document.getRoot()
            );
            writer.setStyle(
              "color",
              "#fff", // White text color in the editable area
              editor.editing.view.document.getRoot()
            );
          });
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return {
            upload: async () => {
              const file = await loader.file;
              return new Promise((resolve) => {
                const reader = new FileReader();

                reader.onload = async function () {
                  const dataUrl = reader?.result;
                  const blob = await b64toBlob(dataUrl);
                  const file = new File([blob], "image"+new Date().valueOf()+".png", {
                    type: blob.type,
                  });
                  const formData = new FormData();
                  formData.append("file", file);
                //   const imgData = await fetch(url, { method: "POST", body: formData, headers: { Authorization: `Bearer ${localStorage.getString(constants.ACCESS_TOKEN)}`, } });
                //   const imgRes = await imgData.json();
                //   resolve({ default: imgRes.result });
                };
                reader.readAsDataURL(file);
              });
            },
          };
        };
        editorRef.current = editor;
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
    </div>
  );
};

export default Editor;