// filterUtils.js
function checkRequiredError(arr) {
    return arr.some(obj =>
        Object.values(obj).some(value => value == "")
    );
}
const checkCalculation = (ticketValue, symbol_name, inputValue) => {
    switch (symbol_name) {
        case "=":
            return ticketValue == inputValue;
        case "!=":
            return ticketValue != inputValue;
        case ">":
            return Number(ticketValue) > Number(inputValue);
        case "<":
            return Number(ticketValue) < Number(inputValue);
        case ">=":
            return Number(ticketValue) >= Number(inputValue);
        case "<=":
            return Number(ticketValue) <= Number(inputValue);
        case "Contains":
            return String(ticketValue).includes(inputValue.toLowerCase());
        case "not contains":
            return !String(ticketValue).includes(inputValue.toLowerCase());
        default:
            return false;
    }
};
const checkIfPresent = (filteredTickets, name, ticketValue)  => {
    for(let i=0;i<filteredTickets?.length;i++) {
        if(filteredTickets[i]?.name==ticketValue) return i;
    }
    return -1;
}
export const showResults = (ticketModifyStatus, filterOptions, dispatch, setFileredDataForTable, setShowFilter, setRequiredError, setActiveFilters) => {
    const filteredTickets = {};
    const statusCheck = [];
    const priorityCheck = []; 
    let tempModify = ticketModifyStatus;
    // Iterate over the ticket statuses
    const checkError = checkRequiredError(filterOptions);
    if (checkError) {
        setRequiredError("All the fields are required");
        return;
    } else {
        setRequiredError("");
    }

    for (const status in tempModify) {
        let tickets = tempModify[status];
        for (let i = 0; i < filterOptions?.length; i++) {
            const { name, symbol_name, inputValue } = filterOptions[i];
            for (let j = 0; j < tickets?.length; j++) {
                const ticketValue = tickets[j][name];
                if (checkCalculation(ticketValue, symbol_name, inputValue)) {
                
                    if (status == "In Progress" && filteredTickets[status] && !statusCheck.includes(j)) {
                        filteredTickets[status].push(tickets[j]);
                        statusCheck.push(j);
                    } else if (status == "priorityStatus" && filteredTickets[status] && !priorityCheck.includes(j)) {
                        filteredTickets[status].push(tickets[j]);
                            priorityCheck.push(j);
                    }
                    else if(!statusCheck.includes(j) && status == "In Progress"){
                        filteredTickets[status] = [tickets[j]];
                        if (status == "In Progress") {
                            statusCheck.push(j);
                        }
                    }
                    else if(!priorityCheck.includes(j) && status == "priorityStatus"){
                        filteredTickets[status] = [tickets[j]];
                        if (status == "priorityStatus") {
                            priorityCheck.push(j);
                        }
                    }
                
                }
            }
        }
    }
    dispatch(setFileredDataForTable(filteredTickets));
    setShowFilter(false);
    dispatch(setActiveFilters(filterOptions))
};
