import "./index.css";
import pdfIcon from "../../assets/images/pdfIcon.png";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDispatch, useSelector } from "react-redux";
import { deleteAttachmentResponse, downloadAttachment, fetchAttachments, fetchDownloadAttachments, setDownloadAttachment, setFileToRemove } from "../../actions/ticketActions";
import { useEffect } from "react";
import convertIntoDateHour from "../EditTicket/convertIntoDateHour";
import { Link, useNavigate } from "react-router-dom";
const isMobile = window.innerWidth <= 768;

const AttachedFile = ({ filename, index, ticketId, setSelectedFile, attachmentsList, urls }) => {
    const {
        downloadAttachmentResponse,
        openEditModal,
        downloadFileTemp
    } = useSelector(state => state.tickets);
    const { deleteAttachmentRes } = useSelector(state => state.tickets);

    const dispatch = useDispatch();
    const handleAttachmentClick = () => {
        if (index != undefined) {
            dispatch(downloadAttachment(attachmentsList[index]));
        } else {
            dispatch(fetchDownloadAttachments({
                ticketId: ticketId,
                fileName: filename?.FileUrl,
            }));
        }
    }
    useEffect(() => {
        if (index == undefined) {
            if (downloadAttachmentResponse?.download_url) {
                const pdfUrl = downloadAttachmentResponse?.download_url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.target = "_blank";
                link.download = filename?.FileUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }, [downloadAttachmentResponse]);

    useEffect(() => {
        if (index != undefined) {
            if (downloadFileTemp?.download_url) {
                const pdfUrl = downloadFileTemp?.download_url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.target = "_blank";
                link.download = downloadFileTemp?.download_url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }, [downloadFileTemp]);
    useEffect(() => {
        return () => {
            dispatch(setDownloadAttachment({}));
        }
    }, []);

    const deleteAttachment = () => {
        if (filename?.AttachmentID) {
            const temp = attachmentsList.filter(item => item.AttachmentID != filename?.AttachmentID);
            setSelectedFile(temp);
            dispatch(setFileToRemove(filename?.AttachmentID));
            // dispatch(deleteAttachmentResponse(filename?.AttachmentID));
        }
        else {
            const temp = attachmentsList.filter(item => item != filename);
            setSelectedFile(temp);
            // dispatch(fetchAttachments(ticketId));
        }
    }

    return (
        <>
            <div className="selected_files">
                <div onClick={handleAttachmentClick}>
                    <img src={pdfIcon} alt="" width={isMobile ? 40 : 60} height={isMobile ? 50 : 70} className="upload_document" />
                </div>
                <div className="selected_files_text" onClick={handleAttachmentClick}>
                    <div>
                        <span className="file_details">{filename?.FileUrl || filename}</span>
                        <br></br>
                        <span className="file_details_time">{filename?.UploadDate && convertIntoDateHour(filename?.UploadDate)}</span>
                        {/* <CheckCircleRoundedIcon sx={{ color: "white", height: "15px", width: "15px" }} />  */}
                        {/* <span className="file_details">Completed</span> */}
                    </div>
                </div>
                <div className="clear_icon">
                    <ClearOutlinedIcon sx={{ color: "white", right: "0px" }} onClick={deleteAttachment} />
                </div>
            </div>
        </>
    );
};

export default AttachedFile;