import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setFeedbackModal, submitFeedBack } from "../../actions/ticketActions";

const FeedbackForm = () => {
    const dispatch = useDispatch();
    const {
        register,
        watch,
        handleSubmit,
        control,
        reset,
        getValues,
        formState: { errors, dirtyFields },
    } = useForm();

    const onSubmit = (data) => {
        dispatch(submitFeedBack(data));
    };

    return (
        <div className="outer_form_feedback">
            <span className="feedback_heading">Feedback</span>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form_div">
                    <label>Subject <span>*</span></label><br></br>
                    <input
                        type="text"
                        name="subject"
                        {...register("subject", { required: true })}
                        placeholder="Title"
                        className="input_field_feedback"
                    />
                    <br></br>
                    {errors.subject && <span className="error_msg">Subject is required</span>}
                </div>

                {<div className="form_div">
                    <label>Description <span>*</span></label>
                    <textarea
                        type="textarea"
                        name="description"
                        {...register("description", { required: true })}
                    />
                    {errors.description && <span  className="error_msg">Description is required</span>}
                </div>}
                <div className="form_div">
                    <div className="btn_div">
                        <button id="cancel_btn" type="button" onClick={() => {reset(); dispatch(setFeedbackModal(false))}}>Cancel</button>
                        <button id="create_ticket" type="submit" className="create_ticket">Submit</button>
                    </div>
                </div>
            </form>
            {/* </div> */}
        </div>
    );
};

export default FeedbackForm;