import "./index.css";
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setFeedbackModal } from "../../actions/ticketActions";
import FeedbackModal from "./FeedbackModal";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const SideDrawer = () => {
    const [selectedItem, setSelectedItem] = useState("timeline");
    const dispatch = useDispatch();
    return (
        <>
            <div className="sidebar_container">
                <div className="sidebar_items">
                    <p className="board_text">Your Board</p>
                    <div className={selectedItem === "timeline" ? "board_active" : "board_items"} onClick={() => setSelectedItem("timeline")}>
                        <ViewTimelineOutlinedIcon className="sidebar_icon" />
                        <span>Timeline</span>
                    </div>
                    {/* <div className={selectedItem === "backlog" ? "board_active" : "board_items"} onClick={() => setSelectedItem("backlog")}>
                        <PendingActionsOutlinedIcon className="sidebar_icon" />
                        <span>Backlog</span>
                    </div>
                    <div className={selectedItem === "activesprints" ? "board_active" : "board_items"} onClick={() => setSelectedItem("activesprints")}>
                        <RecyclingOutlinedIcon className="sidebar_icon" />
                        <span>Active Sprints</span>
                    </div>
                    <div className={selectedItem === "calendar" ? "board_active" : "board_items"} onClick={() => setSelectedItem("calendar")}>
                        <CalendarMonthOutlinedIcon className="sidebar_icon" />
                        <span>Calendar</span>
                    </div>
                    <div className={selectedItem === "reports" ? "board_active" : "board_items"} onClick={() => setSelectedItem("reports")}>
                        <ReceiptLongOutlinedIcon className="sidebar_icon" />
                        <span>Reports</span>
                    </div> */}
                    <div className={selectedItem === "feedback" ? "board_active" : "board_items"} onClick={() => {setSelectedItem("feedback");dispatch(setFeedbackModal(true))}}>
                        <ForumIcon className="sidebar_icon" />
                        <span>Feedback</span>
                    </div>
                    <div className={selectedItem === "settings" ? "board_active" : "board_items"} onClick={() => {setSelectedItem("settings");}}>
                        <SettingsOutlinedIcon className="sidebar_icon" />
                        <span>Settings</span>
                    </div>
                </div>
            </div>
            <FeedbackModal/>
        </>
    );
}
export default SideDrawer;