import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

export default function CircularBackdrop() {
  const {
    isBackdropLoading
} = useSelector(state => state.tickets);
  return (
    <div>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isBackdropLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

