// import ClearAll from "../buttons/ClearAll";
// import HighVoltage from "../buttons/HighVoltage";
// import LowVoltage from "../buttons/LowVoltage";
// import Oem from "../buttons/oem";
// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import "./index.css";
import { setFileredDataForTable, setFilterOptions } from '../../actions/ticketActions';
import { useDispatch, useSelector } from 'react-redux';
import { showResults } from '../dashboard/showResults';
import { useEffect, useState } from 'react';

const ActiveFilters = () => {
    const dispatch = useDispatch();
    const { filterOptions, ticketModifyStatus } = useSelector(state => state.tickets);
    const [isCloseClicked, setIsClosedClicked] = useState(false);
    // const handleCloseFilter = (id) => {
    //     if (filterOptions?.length !== 1) {
    //         const updatedFilterOptions = filterOptions.filter(item => item?.id !== id);
    //         dispatch(setFilterOptions(updatedFilterOptions));
    //         // showResults(ticketModifyStatus, filterOptions, dispatch, setFileredDataForTable, null);
    //         setIsClosedClicked(true);
    //     } else {
    //         const updatedFilterOptions = [{
    //             "id": "1234",
    //             "inputValue": "",
    //             "name": "",
    //             "symbol_name": ""
    //         }];
    //         dispatch(setFilterOptions(updatedFilterOptions));
    //         dispatch(setFileredDataForTable(ticketModifyStatus));
    //     }
    // };
    useEffect(()=>{
        if(isCloseClicked===true && filterOptions) {
            showResults(ticketModifyStatus, filterOptions, dispatch, setFileredDataForTable, null);
            setIsClosedClicked(false);
        }
    }, [isCloseClicked, filterOptions]);

    return (
        <>
            <div className="filter_options">
                <div className="other_filter_options">
                    {/* <p>Active Filters: </p>
                    {
                        filterOptions?.map((item, index) => {
                            return (
                                item?.name && item?.symbol_name && item?.inputValue && <div className="select_all">
                                    <button className="oem_btn">
                                        <span className="filter_text">{`${item?.name} ${item?.symbol_name} ${item?.inputValue}`}</span>
                                        <div onClick={() => handleCloseFilter(item?.id)}>
                                            <CloseOutlinedIcon sx={{ height: "18px", width: "18px", marginLeft: "6px" }} />
                                        </div>
                                    </button>
                                </div>
                            )
                        })
                    } */}
                    {/* <Oem />
                    <LowVoltage/>
                    <HighVoltage/> */}
                    {/* {filterOptions?.length>1 && <ClearAll />} */}
                </div>
            </div>
        </>
    );
}
export default ActiveFilters;