const filterOptionsData = [
    { value: "TicketID", label: "TicketID", name: "TicketID", id: "TicketID" },
    { value: "Title", label: "Title", name: "Title", id: "Title" },
    { value: "WorkTypeID", label: "WorkType", name: "WorkTypeID", id: "WorkTypeID" },
    { value: "PriorityID", label: "Priority", name: "PriorityID", id: "PriorityID" },
    { value: "CategoryID", label: "Category", name: "CategoryID", id: "CategoryID" },
    { value: "AssignedTo", label: "AssignedTo", name: "AssignedToID", id: "AssignedToID" },
    { value: "TicketStatusID", label: "TicketStatus", name: "TicketStatusID", id: "TicketStatusID" },
    { value: "Description", label: "Description", name: "Description", id: "Description" },
    { value: "CustomerName", label: "CustomerName", name: "CustomerName", id: "CustomerName" },
    { value: "AssignedPartNumber", label: "AssignedPartNumber", name: "AssignedPartNumber", id: "AssignedPartNumber" },
    { value: "QuotePartRequired", label: "QuotePartRequired", name: "QuotePartRequired", id: "QuotePartRequired" },
    { value: "OrderPartRequired", label: "OrderPartRequired", name: "OrderPartRequired", id: "OrderPartRequired" },
    { value: "WordSpecsRequired", label: "WordSpecsRequired", name: "WordSpecsRequired", id: "WordSpecsRequired" },
    { value: "CADDrawingsRequired", label: "CADDrawingsRequired", name: "CADDrawingsRequired", id: "CADDrawingsRequired" },
    { value: "ReasonForLate", label: "ReasonForLate", name: "ReasonForLate", id: "ReasonForLate" },
    { value: "Notes", label: "Notes", name: "Notes", id: "Notes" },
    { value: "ReasonForNoQuoteID", label: "ReasonForNoQuote", name: "ReasonForNoQuoteID", id: "ReasonForNoQuoteID" },
    { value: "MistakeCodeID", label: "MistakeCode", name: "MistakeCodeID", id: "MistakeCodeID" },
    { value: "ModifiedDateTime", label: "ModifiedDateTime", name: "ModifiedDateTime", id: "ModifiedDateTime" },
    { value: "CreatedBy", label: "CreatedBy", name: "CreatedBy", id: "CreatedBy" },
    { value: "Price", label: "Price", name: "Price", id: "Price" },
    { value: "Footage", label: "Footage", name: "Footage", id: "Footage" },
    { value: "Creator", label: "Creator", name: "Creator", id: "Creator" },
    { value: "LastModifiedByID", label: "LastModifiedBy", name: "LastModifiedByID", id: "LastModifiedByID" },
    { value: "DateStatusChange", label: "DateStatusChange", name: "DateStatusChange", id: "DateStatusChange" },
    { value: "EstdTimeOfCompletionOriginal", label: "EstdTimeOfCompletionOriginal", name: "EstdTimeOfCompletionOriginal", id: "EstdTimeOfCompletionOriginal" },
    { value: "EstdTimeOfCompletionCurrent", label: "EstdTimeOfCompletionCurrent", name: "EstdTimeOfCompletionCurrent", id: "EstdTimeOfCompletionCurrent" },
    { value: "CreatedTimestamp", label: "CreatedTimestamp", name: "CreatedTimestamp", id: "CreatedTimestamp" },
    { value: "is_sample_available", label: "IsSampleAvailable", name: "is_sample_available", id: "is_sample_available" },
    { value: "can_get_sample", label: "CanGetSample", name: "can_get_sample", id: "can_get_sample" },
    { value: "need_to_see_process", label: "NeedToSeeProcess", name: "need_to_see_process", id: "need_to_see_process" },
    { value: "process_cable_used_in", label: "ProcessCableUsedIn", name: "process_cable_used_in", id: "process_cable_used_in" },
    { value: "specific_pull_force_required", label: "SpecificPullForceReqired", name: "specific_pull_force_required", id: "specific_pull_force_required" },
    { value: "pull_force_value", label: "PullForceValue", name: "pull_force_value", id: "pull_force_value" },
    { value: "automated_handling_process", label: "AutomaticHandlingProcess", name: "automated_handling_process", id: "automated_handling_process" },
    { value: "automated_handling_details", label: "AutomaticHandlingDetails", name: "automated_handling_details", id: "automated_handling_details" },
    { value: "contact_points_with_elements", label: "ContactPointsWithElements", name: "contact_points_with_elements", id: "contact_points_with_elements" },
    { value: "sun_lite_resistant_plastic", label: "SunLightResistancePlastic", name: "sun_lite_resistant_plastic", id: "sun_lite_resistant_plastic" },
    { value: "gamma_ray_sterilize", label: "GammaRaySterilize", name: "gamma_ray_sterilize", id: "gamma_ray_sterilize" },
    { value: "ul_listing_required", label: "UlListingRequired", name: "ul_listing_required", id: "ul_listing_required" },
];
export default filterOptionsData;