import * as React from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setOpenEditModal } from "../../actions/ticketActions";
import EditTicket from "./EditTicket";
import { useNavigate } from "react-router-dom";

export default function BasicModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { openEditModal } = useSelector(state => state.tickets);
    const handleClose = () => { dispatch(setOpenEditModal(false)); navigate("/") };

    return (
        <div>
            <Modal
                open={openEditModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                // className="modal-overlay"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflowY: "auto", // Allows scrolling within the modal
                    // maxHeight: "100vh",

                    // height:"100vh",
                    // overflow: isMobile ? "auto" : "hidden"
                    // overflow: "auto", 
                }}
            >
                <EditTicket />
            </Modal>
        </div>
    );
}
