import { set } from "react-hook-form";
import apiUrl from "../components/common/apiUrl";
import { convertJsonToOptions, convertToOptions } from "../components/EditTicket/commonFunctions";
import { showToastError, showToastSuccess } from "../components/common/toast";
// import { UPLOAD_URL_TEMP, UPLOAD_URL_TICKET } from "./types";
export const SET_INVOLVE_TICKETS = "SET_INVOLVE_TICKETS";
const SET_TICKET_STATUS = "SET_TICKET_STATUS";
const TICKET_STATUS_FILTER = "TICKET_STATUS_FILTER";
const TICKET_STATUS_MAP = "TICKET_STATUS_MAP";
const PRIORITY_MAP = "PRIORITY_MAP";
const WORK_TYPE_MAP = "WORK_TYPE_MAP";

const CATEGORY_TYPE_MAP = "CATEGORY_TYPE_MAP";
const ASSIGNED_TO_PERSON_MAP = "ASSIGNED_TO_PERSON_MAP";
const ASSIGNED_TO_GROUP_MAP = "ASSIGNED_TO_GROUP_MAP";
const REASON_FOR_NO_QUOTE_MAP = "REASON_FOR_NO_QUOTE_ID";
const MISTAKE_CODE_MAP = "MISTAKE_CODE_MAP";
const CUSTOMER_NAME_MAP = "CUSTOMER_NAME_MAP";
const FILTERED_DATA_FOR_TABLE = "FILTERED_DATA_FOR_TABLE";
const FILTER_OPTIONS = "FILTER_OPTIONS";
const OPEN_EDIT_MODAL = "OPEN_EDIT_MODAL";
const OPEN_CREATE_MODAL = "OPEN_CREATE_MODAL";

const MISTAKE_CODE = "MISTAKE_CODE";
const REASON_FOR_NO_QUOTE = "REASON_FOR_NO_QUOTE";
const CONTACT_POINTS = "CONTACT_POINTS";
const UL_LISTINGS = "UL_LISTINGS";
const TICKET_STATUS = "TICKET_STATUS";
const CATEGORIES = "CATEGORIES";
const PRIORITIES = "PRIORITIES";
const WORK_TYPE = "WORK_TYPE";
const GROUP_EMPLOYEE = "GROUP_EMPLOYEE";
const CUSTOMER_NAME = "CUSTOMER_NAME";
const TICKET_DATA = "TICKET_DATA";
const INVOLVE_TICKET_MAP = "INVOLVE_TICKET_MAP";
const ROW_DATA_APPLIED = "ROW_DATA_APPLIED";
const FEEDBACK_MODAL = "FEEDBACK_MODAL";
const EDIT_TICKET = "EDIT_TICKET";
const EDIT_TICKET_RESULT = "EDIT_TICKET_RESULT";
const ACTIVE_FILTERS = "ACTIVE_FILTERS";

const SELECTED_USERS = "SELECTED_USERS";
const COMPLETE_DATA = "COMPLETE_DATA";
const UPLOAD_URL_TICKET = "UPLOAD_URL_TICKET";
const UPLOAD_URL_TEMP = "UPLOAD_URL_TEMP";
const GET_ATTACHMENT = "GET_ATTACHMENT";
const DOWNLOAD_ATTACHMENT = "DOWNLOAD_ATTACHMENT";
const GROUP_TO_EMPLOYE_MAP = "GROUP_TO_EMPLOYE_MAP";
const GROUP_EMPLOYEE_RESPONSE = "GROUP_EMPLOYEE_RESPONSE";
const IS_LOADING = "IS_LOADING";
const QUICK_FILTER_TEXT = "QUICK_FILTER_TEXT";
const OPEN_REVISION_MODAL = "OPEN_REVISION_MODAL";
const DELETE_FILE = "DELETE_FILE";
const EMAILS_TO_NOTIFY = "EMAILS_TO_NOTIFY";
const EMAIL_TO_NOTIFY_LOADING = "EMAIL_TO_NOTIFY_LOADING";
const FILE_TO_REMOVE = "FILE_TO_REMOVE";
const ADD_COMMENTS = "ADD_COMMENTS";
const GET_COMMENTS = "GET_COMMENTS";
const COMMENT_IS_LOADING = "COMMENT_IS_LOADING";
const LOGIN_PAYLOAD = "LOGIN_PAYLOAD";
const LOGIN_TOKEN = "LOGIN_TOKEN";
const IS_PROTECTED = "IS_PROTECTED";
const IS_LOGGEDIN = "IS_LOGGEDIN";
const TICKET_HISTORY = "TICKET_HISTORY";
const IS_HISTORY_LOADING = "IS_HISTORY_LOADING";
const USER_EMAIL_MAP = "USER_EMAIL_MAP";
const REVISION_TICKET = "REVISION_TICKET"; 
const PARENT_TICKET = "PARENT_TICKET";
const LINEAGE_DATA = "LINEAGE_DATA";
const TICKET_TITLE_MAP = "TICKET_TITLE_MAP";
const NOTIFICATIONS_LIST = "NOTIFICATIONS_LIST";
const MARK_NOTIFICATION = "MARK_NOTIFICATION";
const NOTIFICATION_STATUS_MAP = "NOTIFICATION_STATUS_MAP";
const FEEDBACK_RESULT = "FEEDBACK_RESULT";
const EMAILS_TO_NOTIFY_MAP = "EMAILS_TO_NOTIFY_MAP";
const EMAILS_TO_NOTIFY_MAP_DATA = "EMAILS_TO_NOTIFY_MAP_DATA";
const DOWNLOAD_FILE_TEMP = "DOWNLOAD_FILE_TEMP";
const ATTACHMENT_LOADING  = "ATTACHMENT_LOADING";
const PUTSIGNED_SUCCESS = "PUTSIGNED_SUCCESS";
const IS_BACKDROP_LOADING = "IS_BACKDROP_LOADING";

export const setInvolveTickets = (tickets) => ({
  type: SET_INVOLVE_TICKETS,
  payload: tickets,
});
const setTicketModifyStatus = (statusList) => ({
  type: SET_TICKET_STATUS,
  payload: statusList
})
const setTicketStatusFilter = (ticektStatusFilterList) => ({
  type: TICKET_STATUS_FILTER,
  payload: ticektStatusFilterList
})

const setTicketStatusMap = (ticketStatusMapId) => ({
  type: TICKET_STATUS_MAP,
  payload: ticketStatusMapId
})
const setPriorityMap = (priorityMapId) => ({
  type: PRIORITY_MAP,
  payload: priorityMapId
})
const setWorkTypeMap = (workTypeMap) => ({
  type: WORK_TYPE_MAP,
  payload: workTypeMap
})
const setCategoryMap = (categoryMap) => ({
  type: CATEGORY_TYPE_MAP,
  payload: categoryMap
})
const setAssignedToPersonMap = (assignedToPersonMap) => ({
  type: ASSIGNED_TO_PERSON_MAP,
  payload: assignedToPersonMap
})
const setAssignedToGroupMap = (assignedToGroupMap) => ({
  type: ASSIGNED_TO_GROUP_MAP,
  payload: assignedToGroupMap
})
const setReasonForNoQuoteMap = (reasonForNoQuoteMap) => ({
  type: REASON_FOR_NO_QUOTE_MAP,
  payload: reasonForNoQuoteMap
})
const setMistakeCodeMap = (mistakeCodeMap) => ({
  type: MISTAKE_CODE_MAP,
  payload: mistakeCodeMap
})
const setFileredDataForTable = (filteredDataForTable) => ({
  type: FILTERED_DATA_FOR_TABLE,
  payload: filteredDataForTable
})
const setFilterOptions = (filterOptions) => ({
  type: FILTER_OPTIONS,
  payload: filterOptions
})
const setOpenEditModal = (value) => ({
  type: OPEN_EDIT_MODAL,
  payload: value
})

const setMistakeCodes = (value) => ({
  type: MISTAKE_CODE,
  payload: value
})
const setReasonForNoQuote = (value) => ({
  type: REASON_FOR_NO_QUOTE,
  payload: value
})
const setContactPoints = (value) => ({
  type: CONTACT_POINTS,
  payload: value
})
const setUlListings = (value) => ({
  type: UL_LISTINGS,
  payload: value
})
const setTicketStatus = (value) => ({
  type: TICKET_STATUS,
  payload: value
})
const setCategories = (value) => ({
  type: CATEGORIES,
  payload: value
})
const setPriorities = (value) => ({
  type: PRIORITIES,
  payload: value
})
const setWorkTypes = (value) => ({
  type: WORK_TYPE,
  payload: value
})
const setGroupEmployeData = (value) => ({
  type: GROUP_EMPLOYEE,
  payload: value
})
const setTicketData = (value) => ({
  type: TICKET_DATA,
  payload: value
})
const setInvolveTicketMap = (value) => ({
  type: INVOLVE_TICKET_MAP,
  payload: value
})
const setOpenCreateModal = (value) => ({
  type: OPEN_CREATE_MODAL,
  payload: value
})
const setRowDataApplied = (value) => ({
  type: ROW_DATA_APPLIED,
  payload: value
})
const setFeedbackModal = (value) => ({
  type: FEEDBACK_MODAL,
  payload: value
})
const setCustomerNameMap = (value) => ({
  type: CUSTOMER_NAME_MAP,
  payload: value
})
const setCustomerName = (value) => ({
  type: CUSTOMER_NAME,
  payload: value
})
const setEditTicket = (value) => ({
  type: EDIT_TICKET,
  payload: value
})
const setEditTicketResult = (value) => ({
  type: EDIT_TICKET_RESULT,
  payload: value
})
const setActiveFilters = (value) => ({
  type: ACTIVE_FILTERS,
  payload: value
})
const setSelectedUsers = (value) => ({
  type: SELECTED_USERS,
  payload: value
})
const setCompleteData = (value) => ({
  type: COMPLETE_DATA,
  payload: value
})
const setUploadUrlTicket = (value) => ({
  type: UPLOAD_URL_TICKET,
  payload: value
})
const setUploadUrlTemp = (value) => ({
  type: UPLOAD_URL_TEMP,
  payload: value
})
const setTicketAttachment = (value) => ({
  type: GET_ATTACHMENT,
  payload: value
});
const setDownloadAttachment = (value) => ({
  type: DOWNLOAD_ATTACHMENT,
  payload: value
});
const setGroupToEmployeMap = (value) => ({
  type: GROUP_TO_EMPLOYE_MAP,
  payload: value
});
const setGroupEmployeeResponse = (value) => ({
  type: GROUP_EMPLOYEE_RESPONSE,
  payload: value
});
const setIsLoading = (value) => ({
  type: IS_LOADING,
  payload: value
});
const setQuickFilterText = (value) => ({
  type: QUICK_FILTER_TEXT,
  payload: value
});
const setOpenRevisionModal = (value) => ({
  type: OPEN_REVISION_MODAL,
  payload: value
});
const setDeleteFile = (value) => ({
  type: DELETE_FILE,
  payload: value
});
const setEmailsToNotify = (value) => ({
  type: EMAILS_TO_NOTIFY,
  payload: value
});
const setEmailsToNotifyLoading = (value) => ({
  type: EMAIL_TO_NOTIFY_LOADING,
  payload: value
});
const setFileToRemove = (value) => ({
  type: FILE_TO_REMOVE,
  payload: value
});
const setAddComments = (value) => ({
  type: ADD_COMMENTS,
  payload: value
});
const setCommentList = (value) => ({
  type: GET_COMMENTS,
  payload: value
});
const setCommentIsLoading = (value) => ({
  type: COMMENT_IS_LOADING,
  payload: value
});
const setLoginPayload = (value) => ({
  type: LOGIN_PAYLOAD,
  payload: value
});
const setLoginToken = (value) => ({
  type: LOGIN_TOKEN,
  payload: value
})
const setIsProtected = (value) => ({
  type: IS_PROTECTED,
  payload: value
})
const setIsLoggedIn = (value) => ({
  type: IS_LOGGEDIN,
  payload: value
})
const setTicketHistory = (value) => ({
  type: TICKET_HISTORY,
  payload: value
})
const setHistoryLoading = (value) => ({
  type: IS_HISTORY_LOADING,
  payload: value
})
const setUserEmailMap  = (value) => ({
  type: USER_EMAIL_MAP,
  payload: value
})
const setRevisionData  = (value) => ({
  type: REVISION_TICKET,
  payload: value
})
const setParentTicket  = (value) => ({
  type: PARENT_TICKET,
  payload: value
})
const setLineageData  = (value) => ({
  type: LINEAGE_DATA,
  payload: value
})
const setTicketTitleMap  = (value) => ({
  type: TICKET_TITLE_MAP,
  payload: value
})
const setNotificationsList = (value) => ({
  type: NOTIFICATIONS_LIST,
  payload: value
})
const setMarkNotification = (value) => ({
  type: MARK_NOTIFICATION,
  payload: value
})
const setNotificationStatusMap = (value) => ({
  type: NOTIFICATION_STATUS_MAP,
  payload: value
})
const setFeedBackResult = (value) => ({
  type: FEEDBACK_RESULT,
  payload: value
})
const setEmailsToNotifyMap = (value) => ({
  type: EMAILS_TO_NOTIFY_MAP,
  payload: value
})
const setEmailsToNotifyMapData = (value) => ({
  type: EMAILS_TO_NOTIFY_MAP_DATA,
  payload: value
})
const setDownloadFileTemp = (value) => ({
  type: DOWNLOAD_FILE_TEMP,
  payload: value
})
const setAttachmentLoading  = (value) => ({
  type: ATTACHMENT_LOADING,
  payload: value
})
const setPutSignedSuccess = (value) => ({
  type: PUTSIGNED_SUCCESS,
  payload: value
})
const setIsBackdropLoading = (value) => ({
  type: IS_BACKDROP_LOADING,
  payload: value
})

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

// Thunk action creator
export const fetchInvolveTickets = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.involvingTickets, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setInvolveTickets(data));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchMistakeCode = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.mistakeCode, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setMistakeCodes(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchReasonForNoQuote = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.reasonForNoCode, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setReasonForNoQuote(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchContactPoints = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.contactPoints, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setContactPoints(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchUlListings = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.ulListingUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setUlListings(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};
const fetchTicketStatus = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.ticketStatusListing, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setTicketStatus(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchCategories = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.categoriesListing, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setCategories(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchPriorities = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.prioritiesListing, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setPriorities(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchWorkTypes = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.workTypesListing, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setWorkTypes(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchGroupEmployeData = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.groupEmployeList, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setGroupEmployeData(convertJsonToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchCustomerNameData = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.customerName, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setCustomerName(convertToOptions(data)));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};
const editTicketData = (upcomingPayload) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.editTicket, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        dispatch(setIsBackdropLoading(false));
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setOpenEditModal(false));
      dispatch(setEditTicket(data));
      dispatch(fetchNotificationList());
      dispatch(fetchInvolveTickets());
      dispatch(setIsBackdropLoading(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
      dispatch(setIsBackdropLoading(false));
      showToastError("Not updated");
    }
  });
};

const uploadUrlTicketEpic = (upcomingPayload) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.generateUploadUrlTicket, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setUploadUrlTicket(data));
      dispatch(fetchInvolveTickets());
      // dispatch(setAttachmentLoading(false));
      // dispatch(setOpenEditModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};

const uploadUrlTempEpic = (upcomingPayload) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.generateUploadUrlTemp, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setUploadUrlTemp(data));
      dispatch(fetchInvolveTickets());
      // dispatch(setAttachmentLoading(false));
      // dispatch(setOpenEditModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};

const putSignedUrl = (upcomingPayload, dispatch) => {
  return debounce(async () => {
    try {
      const response = await fetch(upcomingPayload?.url, {
        method: 'PUT',
        // headers: {
        //   'Content-Type': upcomingPayload?.data?.type,
        //   'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        // },
        body: upcomingPayload?.data
      });
      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }
      dispatch(setAttachmentLoading(false));
      dispatch(setPutSignedSuccess({
        code:"00000",
        message:"success"
      }))
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};

const fetchAttachments = (ticketId) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.getAttachment(ticketId), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setTicketAttachment(data));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};
const fetchDownloadAttachments = (upcomingData) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.downloadAttachment(upcomingData?.ticketId, upcomingData?.fileName), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setDownloadAttachment(data));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const fetchGroupEmployeeResponse = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.groupEmployeMapUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setGroupEmployeeResponse(data));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};

const deleteAttachmentResponse = (id) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.deleteFile(id), {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        },
      });
      const data = await response.json();
      dispatch(setDeleteFile(data));
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  });
};

const getEmailsToNotify = (id) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.emailsToNotifyUrl(id), {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
      });
      const data = await response.json();
      dispatch(setEmailsToNotify(data));
      dispatch(setEmailsToNotifyLoading(false));
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  });
};
const createComments = (upcomingPayload) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.addComments, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
        body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setAddComments(data));
      // dispatch(fetchInvolveTickets());
      // dispatch(setOpenEditModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};
const getCommentsList = (ticketId) => {
  return debounce(async (dispatch) => {
    dispatch(setCommentIsLoading(true));
    try {
      const response = await fetch(apiUrl.getComments(ticketId), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setCommentList(data));
      dispatch(setCommentIsLoading(false));
      // dispatch(fetchInvolveTickets());
      // dispatch(setOpenEditModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};

const userLogin = (upcomingPayload) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.loginUser, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setLoginToken(data));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};
const userProtected = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.protectedUser, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });

      if (!response.ok) {
        if (window.location.pathname != "/login") {
          window.location.href = "/login";
        }
        throw new Error('Failed to edit ticket');
      }
      const data = await response.json();
      dispatch(setIsProtected(data));
      if (window.location.pathname == "/login") {
        window.location.href = "/";
      }
    } catch (error) {
      console.error('Error editing ticket:', error);
      if (window.location.pathname != "/login") {
        window.location.href = "/login";
      }
    }
  });
};
const getTicketHistory = (ticketId) => {
  return debounce(async (dispatch) => {
    dispatch(setCommentIsLoading(true));
    try {
      const response = await fetch(apiUrl.getHistory(ticketId), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setTicketHistory(data));
      dispatch(setHistoryLoading(false));
      // dispatch(setCommentIsLoading(false));
      // dispatch(fetchInvolveTickets());
      // dispatch(setOpenEditModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};

const createRevisionTicket = (upcomingPayload) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.createRevision, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
        body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        dispatch(setIsBackdropLoading(false));
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setRevisionData(data));
      dispatch(fetchInvolveTickets());
      dispatch(setIsBackdropLoading(false));
      dispatch(setOpenRevisionModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
      dispatch(setIsBackdropLoading(false));
    }
  });
};
const fetchLineageTicket= (ticketId) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.ticketLineage(ticketId), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setLineageData(data));
      dispatch(fetchNotificationList());
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};
const fetchNotificationList = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.getNotificationList, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }
      const data = await response.json();
      dispatch(setNotificationsList(data));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};
const markNotificationStatus = (ticketId) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.markNotificationCheck(ticketId), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }
      const data = await response.json();
      dispatch(setMarkNotification(data));
      dispatch(fetchNotificationList());
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};
const submitFeedBack = (upcomingPayload) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.feedback, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setFeedBackResult(data));
      dispatch(setFeedbackModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};
const fetchEmailsToNotifyMap = () => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.emailsToNotifyMapUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
        }
      });
      const data = await response.json();
      dispatch(setEmailsToNotifyMap(data));
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  });
};
const downloadAttachment = (filename) => {
  return debounce(async (dispatch) => {
    try {
      const response = await fetch(apiUrl.generateDownloadLink(filename), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        // body: JSON.stringify(upcomingPayload)
      });

      if (!response.ok) {
        throw new Error('Failed to edit ticket');
      }

      const data = await response.json();
      dispatch(setDownloadFileTemp(data));
      // dispatch(fetchInvolveTickets());
      // dispatch(setOpenEditModal(false));
    } catch (error) {
      console.error('Error editing ticket:', error);
    }
  });
};
export {
  setTicketModifyStatus,
  setTicketStatusFilter,
  setTicketStatusMap,
  setPriorityMap,
  setWorkTypeMap,

  setCategoryMap,
  setAssignedToPersonMap,
  setAssignedToGroupMap,
  setReasonForNoQuoteMap,
  setMistakeCodeMap,
  setFileredDataForTable,
  setFilterOptions,
  setOpenEditModal,
  setOpenCreateModal,

  setMistakeCodes,
  fetchMistakeCode,
  setReasonForNoQuote,
  fetchReasonForNoQuote,
  setContactPoints,
  setUlListings,
  setTicketStatus,
  setCategories,
  setPriorities,
  setWorkTypes,
  setGroupEmployeData,
  fetchContactPoints,
  fetchUlListings,
  fetchTicketStatus,
  fetchCategories,
  fetchPriorities,
  fetchWorkTypes,
  fetchGroupEmployeData,
  setTicketData,
  setInvolveTicketMap,
  setRowDataApplied,
  setFeedbackModal,
  setCustomerNameMap,
  setCustomerName,
  fetchCustomerNameData,
  setEditTicket,
  editTicketData,
  setEditTicketResult,
  setActiveFilters,
  setSelectedUsers,
  setCompleteData,
  setUploadUrlTicket,
  setUploadUrlTemp,
  uploadUrlTempEpic,
  uploadUrlTicketEpic,
  putSignedUrl,
  fetchAttachments,
  setDownloadAttachment,
  fetchDownloadAttachments,
  fetchGroupEmployeeResponse,
  setGroupEmployeeResponse,
  setGroupToEmployeMap,
  setIsLoading,
  setQuickFilterText,
  setOpenRevisionModal,
  setDeleteFile,
  deleteAttachmentResponse,
  setEmailsToNotify,
  getEmailsToNotify,
  setEmailsToNotifyLoading,
  setFileToRemove,
  setAddComments,
  createComments,
  setCommentList,
  getCommentsList,
  setCommentIsLoading,
  setLoginPayload,
  userLogin,
  setLoginToken,
  userProtected,
  setIsLoggedIn,
  getTicketHistory,
  setTicketHistory,
  setHistoryLoading,
  setUserEmailMap,
  createRevisionTicket,
  setRevisionData,
  setParentTicket,
  setLineageData,
  fetchLineageTicket,
  setTicketTitleMap,
  setNotificationsList,
  fetchNotificationList,
  setMarkNotification,
  markNotificationStatus,
  setNotificationStatusMap,
  submitFeedBack,
  setFeedBackResult,
  fetchEmailsToNotifyMap,
  setEmailsToNotifyMap,
  setEmailsToNotifyMapData,
  setDownloadFileTemp,
  downloadAttachment,
  setAttachmentLoading,
  setPutSignedSuccess,
  setIsBackdropLoading,
};