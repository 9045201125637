import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import React, { useMemo, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTicketStatus, setOpenEditModal } from "../../actions/ticketActions";
import columnDefs from "./tableColumns";
import CircularProgress from "@mui/material/CircularProgress";
import sortBasedOnPriority from "./sortBasedOnPriority";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import formatDateToDDMMYYYY from "./formatDateToDDMMYYYY";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { BorderColor } from "@mui/icons-material";
const isMobile = window.innerWidth <= 768;
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  MultiFilterModule,
  SetFilterModule,
]);

const InProgress = ({ title, colorcode, statusTable, loading, setLoading }) => {
  const { filteredDataForTable, selectedUsers, groupToEmployeMap, assignedToPersonMap, quickFilterText, openEditModal, userEmailmap, notificationStatusMap, emailsToNotifyMapData, involveTickets } = useSelector((state) => state.tickets);
  const [rowData, setRowData] = useState([]);
  const [completeRow, setCompleteRow] = useState([]);
  const [gridApi, setGridApi] = useState(null); // State for storing gridApi
  const dispatch = useDispatch();
  const gridRef = useRef();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get("ticketId");
  // Grid styling
  const [gridHeight, setGridHeight] = useState(""); // Default desktop height
  const rowHeight = 90;
  const rowMobileHeight = 42;

  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "auto",
    }),
    []
  );
  const gridStyle = useMemo(
    () => ({
      height: rowData?.length > 0 ? gridHeight : "300px",
      maxHeight: isMobile ? gridHeight : "300px",
      width: "100%",
      // borderLeft: `2px solid ${colorcode}`,
      backgroundColor: isMobile ? "none" : "#171717",
      overflowX: "auto",
    }),
    [colorcode, gridHeight, rowData, selectedUsers]
  );

  // Helper to convert CustomerName to JSON array format
  const convertStringToJsonArray = (str) => {
    const items = str?.replace(/[{}]/g, "")?.split(",");
    return items?.map((item) => ({
      label: item,
      value: item,
    }));
  };

  const filterByCreatedBy = () => {
    // Get all the filter values of type "employee" or "group"
    let checkUsers = [...selectedUsers];
    for (let i = 0; i < selectedUsers?.length; i++) {
      if (selectedUsers[i]?.type === "group") {
        checkUsers = [...checkUsers, ...groupToEmployeMap[selectedUsers[i]?.id]]
      }
    }
    const filterValues = checkUsers
      .map(filter => filter.value);
    // Filter the data based on the filterValues
    if (filterValues?.length === 0) { setRowData(completeRow); return; };
    const filteredData = completeRow.filter((item) => filterValues.includes(item.AssignedToPersonID[0]) || filterValues.includes(item?.CreatedBy) || filterValues?.some(value => emailsToNotifyMapData[item?.TicketID]?.includes(value)));
    setRowData(filteredData);
  };
  // Function to set rowData (to prevent resetting)
  const updateRowData = () => {
    const dataToDisplay = filteredDataForTable?.[statusTable] || [];
    if (dataToDisplay?.length > 0) {
      gridApi?.hideOverlay();
      const temp = filteredDataForTable[statusTable]?.map((item) => ({
        TicketID: item.TicketID,
        Title: isMobile ? [
          item.TicketID,
          item.Title,
          item.AssignedToPersonID ?
            item.AssignedToPersonID :
            item.AssignedToGroupID,
          assignedToPersonMap[item.CreatedBy],
          item.TicketStatusID,
          item.PriorityID,
          item.WorkTypeID,
          item.CategoryID,
          item.TicketStatusID,
          item.Description,
          convertStringToJsonArray(item.CustomerName),
          item.AssignedPartNumber,
          item.QuotePartRequired,
          item.OrderPartRequired,
          item.WordSpecsRequired,
          item.CADDrawingsRequire,
          item.ReasonForLate,
          item.Notes,
          item.ReasonForNoQuoteID,
          item.MistakeCodeID,
          formatDateToDDMMYYYY(item.ModifiedDateTime),
          userEmailmap[item.CreatedBy],
          item.Price,
          item.Footage,
          item.Creator,
          item.LastModifiedByID,
          formatDateToDDMMYYYY(item.DateStatusChange),
          formatDateToDDMMYYYY(item.EstdTimeOfCompletionOriginal),
          formatDateToDDMMYYYY(item.EstdTimeOfCompletionCurrent),
          formatDateToDDMMYYYY(item.CreatedTimestamp),
          item.is_sample_available,
          item.can_get_sample,
          item.need_to_see_process,
          item.process_cable_used_in,
          item.specific_pull_force_required == true ? "Yes" : "No",
          item.pull_force_value,
          item.automated_handling_process,
          item.automated_handling_details,
          item.contact_points_with_elements,
          item.sun_lite_resistant_plastic,
          item.ul_listing_required || [],
          item.gamma_ray_sterilize,
          item.AssignedToEmailID,
        ] : item.Title,
        WorkTypeID: item.WorkTypeID,
        PriorityID: item.PriorityID,
        CategoryID: item.CategoryID,
        AssignedToPersonID: item.AssignedToPersonID ? [item.AssignedToPersonID, "Person", item.AssignedToEmailID] : [item.AssignedToGroupID, "Group"],
        // AssignedToGroupID: item.AssignedToGroupID,
        TicketStatusID: item.TicketStatusID,
        Description: item.Description,
        CustomerName: convertStringToJsonArray(item.CustomerName),
        AssignedPartNumber: item.AssignedPartNumber,
        QuotePartRequired: item.QuotePartRequired,
        OrderPartRequired: item.OrderPartRequired,
        WordSpecsRequired: item.WordSpecsRequired,
        CADDrawingsRequired: item.CADDrawingsRequired,
        ReasonForLate: item.ReasonForLate,
        Notes: item.Notes,
        ReasonForNoQuoteID: item.ReasonForNoQuoteID,
        MistakeCodeID: item.MistakeCodeID,
        ModifiedDateTime: formatDateToDDMMYYYY(item.ModifiedDateTime),
        CreatedBy: assignedToPersonMap[item.CreatedBy],
        emailCreatedBy: userEmailmap[item.CreatedBy],
        Price: item.Price,
        Footage: item.Footage,
        Creator: item.Creator,
        LastModifiedByID: item.LastModifiedByID,
        DateStatusChange: formatDateToDDMMYYYY(item.DateStatusChange),
        EstdTimeOfCompletionOriginal: formatDateToDDMMYYYY(item.EstdTimeOfCompletionOriginal),
        EstdTimeOfCompletionCurrent: formatDateToDDMMYYYY(item.EstdTimeOfCompletionCurrent),
        CreatedTimestamp: formatDateToDDMMYYYY(item.CreatedTimestamp),
        is_sample_available: item.is_sample_available,
        can_get_sample: item.can_get_sample,
        need_to_see_process: item.need_to_see_process,
        process_cable_used_in: item.process_cable_used_in,
        specific_pull_force_required: item.specific_pull_force_required == true ? "Yes" : "No",
        pull_force_value: item.pull_force_value,
        automated_handling_process: item.automated_handling_process,
        automated_handling_details: item.automated_handling_details,
        contact_points_with_elements: item.contact_points_with_elements,
        sun_lite_resistant_plastic: item.sun_lite_resistant_plastic,
        ul_listing_required: item.ul_listing_required || [],
        gamma_ray_sterilize: item.gamma_ray_sterilize,
        notification_status: notificationStatusMap[item.TicketID]
      }));
      const sortedData = sortBasedOnPriority(temp);
      setCompleteRow(sortedData);
      filterByCreatedBy(sortedData);
      // setRowData(sortedData);
      setLoading(false);
    } else {
      gridApi?.showLoadingOverlay();
      //   setRowData([]); 
      //   gridApi?.showNoRowsOverlay();
    }
  };

  useEffect(() => {
    updateRowData();
  }, [filteredDataForTable, statusTable, selectedUsers, involveTickets]);

  // Row click logic to dispatch row data to modal
  const onRowClick = (event) => {
    // updateRowData();
    const clickedRowData = event?.data;
    // dispatch(setTicketData(involveTicketMap[clickedRowData?.TicketID]));
    // dispatch(setOpenEditModal(true));
    // updateRowData();
    navigate(`/edit-ticket/?ticketId=${clickedRowData?.TicketID}`);
  };

  useEffect(() => {
    const ticketId = searchParams.get("ticketId");
    updateRowData();
    if (ticketId) {
      dispatch(setOpenEditModal(true));
      updateRowData();
    }
  }, [searchParams.get("ticketId")]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    gridRef.current = params.api;
    setTimeout(() => {
      // Set default filter for 'PriorityID' to 'Urgent'
      params.api.setFilterModel({
        PriorityID: {
          filterType: "set",
          values: ["Urgent"], // Apply "Urgent" as the default filter value
        },
      });

      // Ensure the filter is applied
      params.api.onFilterChanged();
    }, 500);
    params.api.onFilterChanged();
  };
  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption("quickFilterText", quickFilterText);
      gridApi.onFilterChanged();
      const displayedRows = [];
      gridApi?.forEachNodeAfterFilterAndSort((node) => {
        displayedRows.push(node.data);
      });
      if (isMobile) {
        const rowCount = displayedRows.length;
        const calculatedHeight = rowCount * rowMobileHeight;
        setGridHeight(`${calculatedHeight}px`);
      } else {
        const rowCount = displayedRows.length;
        const calculatedHeight = rowCount * (rowHeight);
        setGridHeight(`${calculatedHeight}px`);
      }
    }
  }, [quickFilterText, gridApi, columnDefs, isMobile, rowData, selectedUsers]);

  return (
    <div className="table_main">
      <p className="table_head">{title}</p>
      <div className={`ag-theme-quartz-dark`} style={containerStyle}>
        <div style={gridStyle}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
              // cellStyle: { backgroundColor: "#272727", color: "white" },
              headerClass: "custom-header",
              width: "100%",
            }}
            headerHeight={window.innerWidth <= 768 ? 0 : 40}
            domLayout="normal"
            rowSelection="multiple"
            rowHeight={isMobile ? "80px" : "42px"}
            suppressDragLeaveHidesColumns={false}
            getRowStyle={(params) => {
              if (notificationStatusMap[params.data.TicketID] == false) {
                return { backgroundColor: '#ef6e35', color: "white" };
              } else {
                return { backgroundColor: "#272727", color: "white" };
              }
            }}
            // getRowStyle={(params) => {
            //   if (params.data.PriorityID === "Urgent" && statusTable == "priorityStatus") {
            //     return { borderLeft: '2px solid red' };
            //   } else if (params.data.PriorityID === "Normal" && statusTable == "priorityStatus") {
            //     return { borderLeft: '2px solid white' };
            //   } else {
            //     return { borderLeft: "2px solid green" };
            //   }
            // }}
            // rowStyle={{ backgroundColor: "#272727", color: "white", BorderColor:"green" }}
            onRowClicked={onRowClick}
            onGridReady={onGridReady}
            loadingOverlayComponent={() => (
              loading ? <CircularProgress /> :
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#272727",
                  color: "white",
                  width: "100%"
                }}>
                  No data found
                </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default InProgress;
