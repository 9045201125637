import React, { useState, useEffect } from 'react';

const TicketForm = () => {
    // State for dropdown data
    const [mistakeCodes, setMistakeCodes] = useState([]);
    const [reasonForNoQuote, setReasonForNoQuote] = useState([]);
    const [contactPoints, setContactPoints] = useState([]);
    const [ulListings, setUlListings] = useState([]);
    const [ticketStatus, setTicketStatus] = useState([]);
    const [categories, setCategories] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [workTypes, setWorkTypes] = useState([]);

    // State for form data
    const [formData, setFormData] = useState({
        mistakeCode: '',
        reasonForNoQuote: '',
        contactPoint: '',
        ulListing: '',
        ticketStatus: '',
        category: '',
        priority: '',
        workType: ''
    });

    // Fetch all dropdown data from APIs
    useEffect(() => {
        const fetchData = async () => {
            try {
                const mistakeCodesResponse = await fetch('http://127.0.0.1:8001/dropdowns/mistake_codes');
                const reasonForNoQuoteResponse = await fetch('http://127.0.0.1:8001/dropdowns/reason_for_no_quote');
                const contactPointsResponse = await fetch('http://127.0.0.1:8001/dropdowns/contact_points');
                const ulListingsResponse = await fetch('http://127.0.0.1:8001/dropdowns/ul_listings');
                const ticketStatusResponse = await fetch('http://127.0.0.1:8001/dropdowns/ticket_status');
                const categoriesResponse = await fetch('http://127.0.0.1:8001/dropdowns/categories');
                const prioritiesResponse = await fetch('http://127.0.0.1:8001/dropdowns/priorities');
                const workTypesResponse = await fetch('http://127.0.0.1:8001/dropdowns/work_types');

                // Parse responses
                setMistakeCodes(await mistakeCodesResponse.json());
                setReasonForNoQuote(await reasonForNoQuoteResponse.json());
                setContactPoints(await contactPointsResponse.json());
                setUlListings(await ulListingsResponse.json());
                setTicketStatus(await ticketStatusResponse.json());
                setCategories(await categoriesResponse.json());
                setPriorities(await prioritiesResponse.json());
                setWorkTypes(await workTypesResponse.json());

            } catch (error) {
                console.error("Error fetching dropdown data: ", error);
            }
        };

        fetchData();
    }, []);

    // Handle form input change
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Prepare data to send in POST request
        const ticketData = {
            mistakeCode: formData.mistakeCode,
            reasonForNoQuote: formData.reasonForNoQuote,
            contactPoint: formData.contactPoint,
            ulListing: formData.ulListing,
            ticketStatus: formData.ticketStatus,
            category: formData.category,
            priority: formData.priority,
            workType: formData.workType
        };

        try {
            const response = await fetch('http://127.0.0.1:6000/add-ticket', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(ticketData)
            });

            if (response.ok) {
                alert('Ticket added successfully!');
            } else {
                alert('Failed to add ticket');
            }
        } catch (error) {
            console.error('Error submitting ticket: ', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Mistake Codes Dropdown */}
            <div>
                <label htmlFor="mistakeCode">Mistake Code:</label>
                <select name="mistakeCode" value={formData.mistakeCode} onChange={handleChange}>
                    <option value="">Select Mistake Code</option>
                    {mistakeCodes?.map((code, index) => (
                        <option key={index} value={code}>{code}</option>
                    ))}
                </select>
            </div>

            {/* Reason for No Quote Dropdown */}
            <div>
                <label htmlFor="reasonForNoQuote">Reason for No Quote:</label>
                <select name="reasonForNoQuote" value={formData.reasonForNoQuote} onChange={handleChange}>
                    <option value="">Selct Reason</option>
                    {reasonForNoQuote?.map((reason, index) => (
                        <option key={index} value={reason}>{reason}</option>
                    ))}
                </select>
            </div>

            {/* Contact Points Dropdown */}
            <div>
                <label htmlFor="contactPoint">Contact Point:</label>
                <select name="contactPoint" value={formData.contactPoint} onChange={handleChange}>
                    <option value="">Select Contact Point</option>
                    {contactPoints?.map((point, index) => (
                        <option key={index} value={point}>{point}</option>
                    ))}
                </select>
            </div>

            {/* UL Listings Dropdown */}
            <div>
                <label htmlFor="ulListing">UL Listing:</label>
                <select name="ulListing" value={formData.ulListing} onChange={handleChange}>
                    <option value="">Select UL Listing</option>
                    {ulListings?.map((listing, index) => (
                        <option key={index} value={listing}>{listing}</option>
                    ))}
                </select>
            </div>

            {/* Ticket Status Dropdown */}
            <div>
                <label htmlFor="ticketStatus">Ticket Status:</label>
                <select name="ticketStatus" value={formData.ticketStatus} onChange={handleChange}>
                    <option value="">Select Ticket Status</option>
                    {ticketStatus?.map((status, index) => (
                        <option key={index} value={status}>{status}</option>
                    ))}
                </select>
            </div>

            {/* Categories Dropdown */}
            <div>
                <label htmlFor="category">Category:</label>
                <select name="category" value={formData.category} onChange={handleChange}>
                    <option value="">Select Category</option>
                    {categories?.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
                </select>
            </div>

            {/* Priorities Dropdown */}
            <div>
                <label htmlFor="priority">Priority:</label>
                <select name="priority" value={formData.priority} onChange={handleChange}>
                    <option value="">Select Priority</option>
                    {priorities?.map((priority, index) => (
                        <option key={index} value={priority}>{priority}</option>
                    ))}
                </select>
            </div>

            {/* Work Types Dropdown */}
            <div>
                <label htmlFor="workType">Work Type:</label>
                <select name="workType" value={formData.workType} onChange={handleChange}>
                    <option value="">Select Work Type</option>
                    {workTypes?.map((workType, index) => (
                        <option key={index} value={workType}>{workType}</option>
                    ))}
                </select>
            </div>

            {/* Submit Button */}
            <button type="submit">Submit</button>
        </form>
    );
};


export default TicketForm;
