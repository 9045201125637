import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import convertIntoDateHour from './convertIntoDateHour';
import mapFields from './HistoryTitleMap';

const HistoryTicket = () => {

    const { 
        assignedToPersonMap, 
        ticketHistory, 
        workTypeMap,
        assignedToGroupMap, 
        priorityMapId, 
        categoryTypeMap, 
        ticketStatusMap,
        reasonForNoQuoteMap,
        mistakeCodeMap,
        customerNameMap,
    } = useSelector((state) => state.tickets);
    const historyMapValue = (title, value) => {
        switch (title) {
            case "WorkTypeID":
                return workTypeMap[value];
            case "Title":
                return value;
            case "AssignedToPersonID":
                return assignedToPersonMap[value];
            case "AssignedToGroupID":
                return assignedToGroupMap[value];
            case "PriorityID":
                return priorityMapId[value];
            case "CategoryID":
                return categoryTypeMap[value];
            case "TicketStatusID":
                return ticketStatusMap[value];
            case "ReasonForNoQuoteID":
                return reasonForNoQuoteMap[value];
            case "MistakeCodeID":
                return mistakeCodeMap[value];
            case "Description":
                return "Description";
            case "CustomerID":
                return customerNameMap[value];
            case "contact_points_with_elements":
                return value;
            case "AssignedPartNumber":
                return value;
            case "QuotePartRequired":
                return value;
            case "WordSpecsRequired":
                return value;
            case "OrderPartRequired":
                return value;
            case "CADDrawingsRequired":
                return value;
            case "ReasonForLate":
                return value;
            case "Notes":
                return value;
            case "is_sample_available":
                return value== true?"Yes":"No";
            case "can_get_sample":
                return value== true?"Yes":"No";
            case "need_to_see_process":
                return value== true?"Yes":"No";
            case "process_cable_used_in":
                return "Process Cable Used In";
            case "automated_handling_process":
                return value== true?"Yes":"No";
            case "specific_pull_force_required":
                return value== true?"Yes":"No";
            case "automated_handling_details":
                return value;
            case "pull_force_value":
                return value;
            case "sun_lite_resistant_plastic":
                return value== true?"Yes":"No";
            case "gamma_ray_sterilize":
                return value== true?"Yes":"No";
            case "ul_listing_required":
                return value;
            default:
                break;
        }
    };
    return (
        <>
            {/* <p className="current_day">{day_posted}</p> */}
            {
                ticketHistory?.length > 0 ? ticketHistory?.map((item, key) => {
                    return (
                        <div className="user_history_container">
                            <div className="user_icon_history">
                                <AccountCircleOutlinedIcon sx={{ color: "white", height: "40px", width: "40px" }} />
                                <span className="user_name">{assignedToPersonMap[item?.EmployeeID]}</span>
                            </div>
                            <div className="user_comment_details">
                                <p className="history_title">{mapFields(item?.Field)}</p>
                                <p className="ticket_status">{historyMapValue(item?.Field, item?.OldValue)}</p>
                                <ArrowDownwardIcon sx={{ color: "white", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center" }} />
                                <p className="ticket_status">{historyMapValue(item?.Field, item?.NewValue)}</p>
                                <span className="comment_date">{convertIntoDateHour(item?.Created)}</span>
                            </div>
                        </div>
                    );
                }) : <Box sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", color: "white" }}>
                    <p>
                        No History Available
                    </p>
                </Box>
            }
        </>
    );
}

export default HistoryTicket;