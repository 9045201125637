import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
const isMobile = window.innerWidth <= 768;

const control = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: isMobile?"280px":"100%",
      marginTop: "10px"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(39, 39, 39)",
      color: "#fff",
      width: isMobile?"280px":"100%",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black",
      ":hover": {
        backgroundColor: "#ef6e35",
        color: "white",
      },
    }),
  };

  const CustomMultiValue = (props) => {
    return (
      <components.MultiValue {...props}>
         <div style={{ display: "flex", alignItems: "center" }}>
          {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
          {props.data.label}
        </div>
      </components.MultiValue>
    );
  };
  

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        {/* Render an icon and the option label */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
          {props.data.label}
        </div>
      </components.Option>
    );
  };

const MultiSelectDropdown = ({field, options, onChange, defaultValue }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const customFilter = (option, searchTerm) => {
    const search = searchTerm.toLowerCase();
    return (
      option.label.toLowerCase().includes(search) ||
      (option.data.email && option.data.email.toLowerCase().includes(search))
    );
  };
  // // Convert the string to an array of JSON objects (assuming a conversion function exists)
  useEffect(() => {
    if (defaultValue) {
      setSelectedOptions(defaultValue);
    }
  }, [defaultValue]);

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected); // Update selected options
  };

  return (
    <Select
    {...field}
      className="multi_select_dropdown"
      options={options}
      isMulti={true}
      // value={selectedOptions}
      styles={control}
      // onChange={handleSelectChange}
      defaultValue={defaultValue}
      filterOption={customFilter}
      components={{Option:CustomOption, MultiValue: CustomMultiValue }}
      // formatCreateLabel={(inputValue) => `Create "${inputValue}"`}  
    />
  );
};

export default MultiSelectDropdown;
