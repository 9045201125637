const sortBasedOnPriority = (arr) => {
    const sortedTemp = arr.sort((a, b) => {
        // Sort by PriorityID (Urgent first, Normal second)
        const priorityOrder = { Urgent: 1, Normal: 2 }; 
        const priorityA = priorityOrder[a.PriorityID] || 3; 
        const priorityB = priorityOrder[b.PriorityID] || 3;
  
        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }
  
        return new Date(a.CreatedTimestamp) - new Date(b.CreatedTimestamp);
      });
    return sortedTemp;
}
export default sortBasedOnPriority;