
import { useEffect } from "react";
import RevisionTicketModal from "../EditTicket/RevisionTicketModal";
import BasicModal from "../EditTicket/TicketModal";
import SideDrawer from "../sidebar/SideDrawer";
import CreateTicketModal from "../TicketOperations/CreateTicketModal";
import "./index.css";
import MainBoard from "./MainBoard";
import { userProtected } from "../../actions/ticketActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        editTicket
    } = useSelector(state => state.tickets);
    useEffect(()=>{
        dispatch(userProtected());
    },[]);

      useEffect(()=>{
        if(editTicket?.status=="success") {
          navigate("/");
        }
      },[editTicket]);
    return (
        <>
            <div className="main_container">
            <div className="container_item">
            <SideDrawer/>
            </div>
            <div className="container_item">
                <MainBoard/>
            </div>
            </div>
            <RevisionTicketModal/>
            <CreateTicketModal/>
            <BasicModal/>
        </>
    );
};

export default Dashboard;