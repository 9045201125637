import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import controlStylesList from "./DropdownControllers";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState, useRef } from "react";
import { fetchGroupEmployeData, fetchGroupEmployeeResponse, fetchInvolveTickets, setActiveFilters, setFileredDataForTable, setFilterOptions, setGroupEmployeData, setGroupToEmployeMap, setInvolveTicketMap, setQuickFilterText, setSelectedUsers } from "../../actions/ticketActions";
import { useDispatch, useSelector } from "react-redux";
import { showResults } from "./showResults";
import filterOptionsData from "./filterOptionsData";
import identifiersList from "./identifiersList";
import MultiSelectDropdown from "../TicketOperations/MultiselectDropDown";

const FilterOptions = () => {
    const dispatch = useDispatch();
    const filterRef = useRef(null);
    const [initialValues, setInitialValues] = useState({});
    const [requiredError, setRequiredError] = useState("");
    const { register, handleSubmit, control, reset, watch, formState: { errors } } = useForm({
        defaultValues: initialValues,
    });
    const {
        groupEmployeData,
        groupEmployeeResponse,
        assignedToGroupMap,
        assignedToPersonMap,
    } = useSelector(state => state.tickets);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowUserFilter(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterRef]);

    const { involveTickets, ticketModifyStatus } = useSelector(state => state.tickets);

    const [showUserFilter, setShowUserFilter] = useState(false);
    const mapDataToId = (dataList) => {
        const temp = {};

        for (let i = 0; i < dataList?.length; i++) {
            temp[dataList[i]?.TicketID] = dataList[i];
        }
        dispatch(setInvolveTicketMap(temp));
    }
    useEffect(() => {
        dispatch(fetchInvolveTickets());
    }, [dispatch]);
    useEffect(() => {
        dispatch(setFileredDataForTable(ticketModifyStatus));
    }, [ticketModifyStatus, involveTickets]);

    useEffect(() => {
        mapDataToId(involveTickets)
    }, [involveTickets]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchGroupEmployeData());
            } catch (error) {
                console.error("Error fetching dropdown data: ", error);
            }
        };

        fetchData();
    }, []);

    const getParsedItem = (key, isJson = true) => {
        const item = localStorage?.getItem(key);
        if (!item) return isJson ? [] : '';
        try {
            return isJson ? JSON.parse(item) : item;
        } catch (e) {
            console.error(`Error parsing ${key}:`, e);
            return isJson ? [] : '';
        }
    };
    useEffect(() => {
        dispatch(setGroupEmployeData(getParsedItem("groupEmployeData") || []));
    }, []);
    useEffect(() => {
        localStorage.setItem("groupEmployeData", JSON.stringify(groupEmployeData));
    }, [groupEmployeData]);

    const onSubmit = (data) => {
        dispatch(setSelectedUsers(data?.selectUsers));
        setShowUserFilter(false);
    }
    useEffect(() => {
        dispatch(fetchGroupEmployeeResponse());
    }, []);

    useEffect(() => {
        if (groupEmployeeResponse) {
            const temp = {};
            for (let i = 0; i < groupEmployeeResponse?.length; i++) {
                if (temp[groupEmployeeResponse[i]?.GroupID]) {
                    temp[groupEmployeeResponse[i]?.GroupID].push({ "value": assignedToPersonMap[groupEmployeeResponse[i]?.EmployeeID] })
                } else {
                    temp[groupEmployeeResponse[i]?.GroupID] = [{ "value": assignedToPersonMap[groupEmployeeResponse[i]?.EmployeeID] }];
                }
            }
            dispatch(setGroupToEmployeMap(temp));
        }
    }, [groupEmployeeResponse, assignedToGroupMap]);

    return (
        <>
            <div className="filter_options">
                <div className="other_filter_options">
                    <div className="search_container">
                        <input type="text" placeholder="Search..." onChange={(e)=>dispatch(setQuickFilterText(e.target.value))}/>
                        <SearchOutlinedIcon className="search_icon" />
                    </div>
                    <div className="filter_btn_div">
                        <div className="filter_items">
                            <button className="filter_btn" onClick={() => { setShowUserFilter(!showUserFilter) }}>
                                <FilterAltOutlinedIcon className="filter_icon" />
                                <span className="filter_text">Filter Users</span>
                            </button>
                            {
                                showUserFilter &&
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="filter_items_container" ref={filterRef}>
                                        <div className="filter_items_div">
                                            <Controller
                                                name="selectUsers"
                                                control={control}
                                                render={({ field }) => (
                                                    <MultiSelectDropdown
                                                        field={field}
                                                        options={groupEmployeData}
                                                        value={field.value}
                                                        // className="user_filter_applied"
                                                    />
                                                )}
                                            />
                                            <hr className="filter_seperator"></hr>
                                            <div className="apply_filter_btn">
                                                {/* <div className="add_filter_btn" onClick={handleAddFilter}>
                                            + Add filter
                                        </div> */}
                                                <button type="submit" className="add_filter_btn">
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterOptions;
