import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";

const PDFPreview = () => {
    const [pdfUrl, setPdfUrl] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(()=>{
        setPdfUrl(queryParams.get("fileUrl"))
    },[queryParams])

    if (!pdfUrl) {
        return (
            <div style={{ padding: "20px" }}>
                <h2>No PDF Found</h2>
                <p>Please upload a PDF file from the home page.</p>
            </div>
        );
    }

    return (
        <iframe
            src={pdfUrl}
            title="PDF Preview"
            style={{
                width: "100%",
                height: "100vh",
                border: "1px solid #ddd",
            }}
        ></iframe>
    );
};

export { PDFPreview };
