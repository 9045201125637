import { useEffect, useRef, useState } from "react";
import "./index.css";
import UserComments from "./UserComments";
import HistoryTicket from "./HistoryTicket";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from "react-redux";
import { createComments, getCommentsList, getTicketHistory, setAddComments, setCommentList } from "../../actions/ticketActions";
import { useSearchParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
import convertIntoDateHour from "./convertIntoDateHour";

const Comments = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const hasRun = useRef(false);
    const messagesEndRef = useRef(null);
    const { createCommentResponse, commentList, isCommentLoading, groupEmployeData, ticketHistory, historyLoading, ticketData, assignedToPersonMap} = useSelector((state) => state.tickets);
    const [selectedTab, setSelectedTab] = useState("comments");
    const [commentText, setCommentText] = useState("");
    const [showMentionList, setShowMentionList] = useState(false);
    const [payloadText, setPayloadText] = useState("");
    const [mentionMap, setMentionMap] = useState();
    const [ticketStatusUpdate, setTicketStatusUpdate] = useState();
    const [mentions, setMentions] = useState([]);
    const [filteredMentions, setFilteredMentions] = useState(groupEmployeData);
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo({
                top: messagesEndRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [commentList, ticketHistory]);

    const handleCreateComment = () => {
        if (commentText.trim()) {
            let temp = commentText;
            for (let i = 0; i < mentions?.length; i++) {
                temp = temp.replace(mentions[i]?.display, mentions[i]?.payload);
            }
            const ticketId = searchParams.get("ticketId");
            const paylaod = {
                "ticketID": ticketId,
                "employeeID": 3,
                "content": temp.trim()
            }
            dispatch(createComments(paylaod));
        } else {
            setCommentText("");
        }
    }
    const handleEnterKey = (event) => {
        if (event.key === "Enter") {
            handleCreateComment();
        }
        if (event.key === "Backspace") {
            const words = commentText.split(" ");
            const lastWord = words[words.length - 1];

            // Check if the last word is a mention
            if (lastWord.startsWith("@") && lastWord.endsWith("")) {
                const updatedMentions = mentions.filter(
                    (mention) => mention.display !== lastWord
                );
                setMentions(updatedMentions);
            }
        }
    }
    useEffect(() => {
        if (createCommentResponse?.message == "Comment and mentions added successfully.") {
            setCommentText("");
            const ticketId = searchParams.get("ticketId");
            dispatch(getCommentsList(ticketId));
            dispatch(setAddComments({}));
        }
    }, [createCommentResponse]);
    useEffect(()=>{
        for(let i=0;i<ticketHistory?.length;i++) {
            if(ticketHistory[i]?.Field=="TicketStatusID") {
                setTicketStatusUpdate(convertIntoDateHour(ticketHistory[i]?.Created));
            }
        }
    },[ticketHistory]);

    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
            const ticketId = searchParams.get("ticketId");
            dispatch(getCommentsList(ticketId));
            dispatch(getTicketHistory(ticketId));
        }
    }, []);
    useEffect(() => {
        return () => {
            dispatch(setCommentList([]));
        }
    }, []);

    const handleInputChange = (e) => {
        setCommentText(e.target.value);
        const text = e.target.value;
        setCommentText(text);

        // Detect last @ and text following it
        const lastWord = text.split(" ").pop();
        if (lastWord.startsWith("@")) {
            setShowMentionList(true);
            const query = lastWord.slice(1).toLowerCase(); // Text after @
            const matches = groupEmployeData.filter((user) =>
                user?.label?.toLowerCase().startsWith(query) || user?.email?.toLowerCase().startsWith(query)
            );
            setFilteredMentions(matches);
        } else {
            setShowMentionList(false);
            setFilteredMentions(groupEmployeData);
        }
    }
    const handleSelectMention = (mention) => {
        const words = commentText.split(" ");
        const lastWord = words.pop();
        if (lastWord.startsWith("@")) {
            words.push(`@${mention.label}`);
            // words.push(`<${mention.type}=${mention.id}>`);
        }
        const updatedText = words.join(" ") + " ";
        setCommentText(updatedText);
        const payloadWords = commentText.split(" ");
        payloadWords.pop();
        payloadWords.push(`<${mention.type == "employee" ? "EmployeeID" : "GroupID"}=${mention.id}>`);
        setPayloadText(payloadWords.join(" ") + " ");
        const temp = [...mentions];
        temp.push({ display: `@${mention.label}`, payload: `<${mention.type == "employee" ? "EmployeeID" : "GroupID"}=${mention.id}>` });
        setMentions(temp);
        // setMentions((prev) => [...prev, mention]);
        setShowMentionList(false);
    };

    return (
        <>
            <div className="comments_container">
                <div className="comments_tabs">
                    <p className={selectedTab === "comments" ? "active_comment" : "inactive_comment"} onClick={() => setSelectedTab("comments")}>Comments</p>
                    <p className={selectedTab === "history" ? "active_comment" : "inactive_comment"} onClick={() => setSelectedTab("history")}>History</p>
                </div>
                <div className="comments_container_main" ref={messagesEndRef}>
                    {
                        selectedTab === "comments" &&
                            !isCommentLoading &&
                            <UserComments /> 
                    }
                    {
                        selectedTab === "history" && !historyLoading && <HistoryTicket />
                    }
                    {
                        historyLoading && isCommentLoading && <Box sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress />
                            </Box>
                    }
                </div>
                {showMentionList && <div className="mention_container">
                    {
                        filteredMentions?.map((item) => {
                            return (<>
                                <p className="mention_item" onClick={() => handleSelectMention(item)}>
                                    {item?.label}
                                </p>
                            </>);
                        })
                    }
                </div>}
                {selectedTab === "comments" && <div className="input_with_icon">
                    <input className="add_comments" placeholder="Add a comment" value={commentText} onChange={handleInputChange} onKeyDown={handleEnterKey} />
                    <span style={{ marginTop: "10px", paddingLeft: "-60px" }} onClick={handleCreateComment}><ArrowForwardIcon sx={{ color: "white", backgroundColor: "#ef6e35", height: "25px", width: "30px", borderRadius: "5px", cursor: "pointer", marginLeft: "-70px", marginTop: "-20px" }} /></span>
                </div>}
                <div className="created_timeline">
                    <p className="created_date">Created on <span className="date_text">{ticketData?.CreatedTimestamp && convertIntoDateHour(ticketData?.CreatedTimestamp)}</span></p>
                    <p className="created_date">Status Change <span className="date_text">{ticketStatusUpdate?ticketStatusUpdate:ticketData?.CreatedTimestamp && convertIntoDateHour(ticketData?.CreatedTimestamp) }</span></p>
                    <p className="created_date">Modified by <span className="date_text">{assignedToPersonMap[ticketHistory[ticketHistory?.length-1]?.EmployeeID] || assignedToPersonMap[ticketData?.CreatedBy]}</span></p>
                    <p className="created_date">Last Modified <span className="date_text">{ticketData?.CreatedTimestamp && convertIntoDateHour(ticketData?.ModifiedDateTime)}</span></p>
                    {/* <p className="created_date">Original Est. Completion &nbsp;&nbsp;<span className="date_text">12/07/20224</span></p>
                    <p className="created_date">Original Est. Current  &nbsp;&nbsp;<span className="date_text">10/07/20224</span></p> */}
                </div>
            </div>
        </>
    );
}

export default Comments;