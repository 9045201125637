import { useForm } from "react-hook-form";
import "./index.css";
import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, userLogin, userProtected } from "../../actions/ticketActions";
import { useLocation, useNavigate } from "react-router-dom";

const LoginPage = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        loginToken,
        isProtected,
        isLoggedIn,
    } = useSelector(state => state.tickets);

    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = (data) => {
        dispatch(userLogin(data));
    };
    useEffect(()=>{
        if(loginToken?.access_token) {
            localStorage.setItem("accessToken", (loginToken?.access_token));
            dispatch(userProtected());
        }
    },[loginToken]);

    useEffect(()=>{
        if(isProtected?.message=="Welcome!") {
            dispatch(setIsLoggedIn(true));
            if(location?.pathname=="/login"){
            navigate("/");
            }
        } else {
            navigate("/login");
        }
    },[isProtected]);

    // useEffect(()=>{
    //     if(isLoggedIn) {
    //         navigate("/");
    //     } else {
    //         navigate("/login");
    //     }
    // },[isLoggedIn])

    return (
        <>
            <div className="login_outer_div">
                <div className="login_form_div">
                    <form className="login_form" onSubmit={handleSubmit(onSubmit)}>
                        {/* Email Field */}
                        <div className="form_group">
                            <label htmlFor="email">Email <span className="error_message">*</span></label>
                            <br></br>
                            <input
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                        message: "Invalid email address",
                                    },
                                })}
                                className={errors.email ? "error_input" : "login_input"}
                            />
                            <br></br>
                            {errors.email && <span className="error_message">{errors.email.message}</span>}
                        </div>

                        {/* Password Field */}
                        <div className="form_group">
                            <label htmlFor="password">Password <span className="error_message">*</span></label>
                            <br></br>
                            <div className="password_container">
                                <input
                                    id="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter your password"
                                    {...register("password", {
                                        required: "Password is required",
                                        minLength: {
                                            value: 6,
                                            message: "Password must be at least 6 characters",
                                        },
                                    })}
                                    className={errors.password ? "error_input" : "login_input"}
                                />
                                <span
                                    className="password_toggle"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? <VisibilityIcon sx={{ color: "black", height: "20px", width: "20px" }} /> : <VisibilityOffIcon sx={{ color: "black", height: "20px", width: "20px" }} />}
                                </span>
                            </div>
                            <br></br>
                            {errors.password && <span className="error_message">{errors.password.message}</span>}
                        </div>

                        {/* Submit Button */}
                        <button type="submit" className="form_button">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
export default LoginPage;