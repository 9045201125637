const isSampleAvailable = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    }
];

const getAvailableSample = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    }
];

const seeProcess = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    }
];

const pullForceNeeded = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    }
];
const automatedHandling = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    }
];

const sunLightResistancePlastic = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    }
];

const gammaRays = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    }
];

const UlListing = [
    {
        "id": "UL 1063",
        "label": "UL 1063: Machine Tool Wire",
        "value": "UL 1063: Machine Tool Wire"
    },
    {
        "id": "UL 1263",
        "label": "UL 1263: Irrigation Cable",
        "value": "UL 1263: Irrigation Cable"
    },
    {
        "id": "UL 1277",
        "label": "UL 1277: Power & Control Tray Cable",
        "value": "UL 1277: Power & Control Tray Cable"
    },
    {
        "id": "UL 1309",
        "label": "1309: Marine Shipboard Cable",
        "value": "1309: Marine Shipboard Cable"
    },
    {
        "id": "UL 1424",
        "label": "UL 1424: Fire Alarm Cable",
        "value": "UL 1424: Fire Alarm Cable"
    },
    {
        "id": "UL 1425",
        "label": "UL 1425: Non-Power Limited Fire Alarm",
        "value": "UL 1425: Non-Power Limited Fire Alarm"
    },
    {
        "id": "UL 1569",
        "label": "UL 1569: Metal Clad & Teck 90",
        "value": "UL 1569: Metal Clad & Teck 90"
    },
    {
        "id": "UL 1650",
        "label": "UL 1650: Portable Power Cable",
        "value": "UL 1650: Portable Power Cable"
    },
    {
        "id": "UL 1690",
        "label": "UL 1690: Data Processing Cable",
        "value": "UL 1690: Data Processing Cable"
    },
    {
        "id": "UL 2250",
        "label": "UL 2250: Instrumentation Tray Cable",
        "value": "UL 2250: Instrumentation Tray Cable"
    },
    {
        "id": "UL 2273",
        "label": "UL 2273: Festoon Cable",
        "value": "UL 2273: Festoon Cable"
    },
    {
        "id": "UL 2277",
        "label": "UL 2277: Flexible Motor Supply Cable",
        "value": "UL 2277: Flexible Motor Supply Cable"
    },
    {
        "id": "UL 2571",
        "label": "UL 2571: Irrigation Feeder",
        "value": "UL 2571: Irrigation Feeder"
    },
    {
        "id": "UL 2592",
        "label": "UL 2592: Low Voltage LED Wire",
        "value": "UL 2592: Low Voltage LED Wire"
    },
    {
        "id": "UL 3003",
        "label": "UL 3003: DG Distributed Generation Cables",
        "value": "UL 3003: DG Distributed Generation Cables"
    },
    {
        "id": "UL 4703",
        "label": "UL 4703: Photovoltaic Wire",
        "value": "UL 4703: Photovoltaic Wire"
    },
    {
        "id": "UL 493",
        "label": "UL 493 : Miscellaneous Wires",
        "value": "UL 493 : Miscellaneous Wires"
    },
    // {
    //     "id": "UL 1063",
    //     "label": "UL 62: Electric Vehicle Cable",
    //     "value": "UL 62: Electric Vehicle Cable"
    // },
    {
        "id": "UL 62",
        "label": "UL 62: Flexible Portable Cords",
        "value": "UL 62: Flexible Portable Cords"
    },
    // {
    //     "id": "UL 62",
    //     "label": "UL 62 : Hoistway Cables",
    //     "value": "UL 62 : Hoistway Cables"
    // },
    {
        "id": "UL 66",
        "label": "UL 66: Fixture Wire",
        "value": "UL 66: Fixture Wire"
    },
    {
        "id": "UL 719",
        "label": "UL 719 : Non Metallic Sheathed Cable Simtra",
        "value": "UL 719 : Non Metallic Sheathed Cable Simtra"
    },
    {
        "id": "UL 814",
        "label": "UL 814 : Gas Tube Sign Cables",
        "value": "UL 814 : Gas Tube Sign Cables"
    },
];

const processCableList = [
    {
        "label": "Flexibility",
        "value": "Flexibility"
    },
    {
        "label": "Potting",
        "value": "Potting"
    },
    {
        "label": "Environmental",
        "value": "Environmental"
    },
];

const cableContactPointList = [
    {
        "label": "Oils",
        "value": "Oils"
    },
    {
        "label": "Over molding",
        "value": "Over molding"
    },
    {
        "label": "Other Plastic",
        "value": "Other Plastic"
    },
    {
        "label": "Chemical",
        "value": "Chemical"
    },
    {
        "label": "Fuel",
        "value": "Fuel"
    },
    {
        "label": "Oil",
        "value": "Oil"
    },
    {
        "label": "Sun",
        "value": "Sun"
    },
    {
        "label": "Surfactants",
        "value": "Surfactants"
    },
    {
        "label": "Detergents",
        "value": "Detergents"
    },
];

const UlListingMapping = {
    "UL 1063": "UL 1063: Machine Tool Wire",
    "UL 1263": "UL 1263: Irrigation Cable",
    "UL 1277": "UL 1277: Power & Control Tray Cable",
    "UL 1309": "UL 1309: Marine Shipboard Cable",
    "UL 1424": "UL 1424: Fire Alarm Cable",
    "UL 1425": "UL 1425: Non-Power Limited Fire Alarm",
    "UL 1569": "UL 1569: Metal Clad & Teck 90",
    "UL 1650": "UL 1650: Portable Power Cable",
    "UL 1690": "UL 1690: Data Processing Cable",
    "UL 2250": "UL 2250: Instrumentation Tray Cable",
    "UL 2273": "UL 2273: Festoon Cable",
    "UL 2277": "UL 2277: Flexible Motor Supply Cable",
    "UL 2571": "UL 2571: Irrigation Feeder",
    "UL 2592": "UL 2592: Low Voltage LED Wire",
    "UL 3003": "UL 3003: DG Distributed Generation Cables",
    "UL 4703": "UL 4703: Photovoltaic Wire",
    "UL 493": "UL 493 : Miscellaneous Wires",
    "UL 62": "UL 62: Flexible Portable Cords",
    "UL 66": "UL 66: Fixture Wire",
    "UL 719": "UL 719 : Non Metallic Sheathed Cable Simtra",
    "UL 814": "UL 814 : Gas Tube Sign Cables",
};


export { 
    isSampleAvailable, 
    getAvailableSample, 
    seeProcess, 
    pullForceNeeded, 
    automatedHandling, 
    sunLightResistancePlastic, 
    gammaRays, 
    UlListing,
    processCableList,
    cableContactPointList,
    UlListingMapping
};