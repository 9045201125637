import FilterOptions from "./FilterOptions";
import "./index.css";
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import ActiveFilters from "./Active Filters";
import InProgress from "./InProgress";
import { useEffect, useRef, useState } from "react";
import apiUrl from "../common/apiUrl";
import { fetchCustomerNameData, fetchEmailsToNotifyMap, fetchTicketStatus, setAssignedToGroupMap, setAssignedToPersonMap, setCategoryMap, setCustomerName, setCustomerNameMap, setEmailsToNotifyMapData, setIsLoading, setMistakeCodeMap, setNotificationStatusMap, setOpenCreateModal, setPriorityMap, setReasonForNoQuoteMap, setTicketModifyStatus, setTicketTitleMap, setUserEmailMap, setWorkTypeMap } from "../../actions/ticketActions";
import { useDispatch, useSelector } from "react-redux";
import { setTicketStatusMap } from "../../actions/ticketActions";
import CreateTicketModal from "../TicketOperations/CreateTicketModal";
import { convertLength } from "@mui/material/styles/cssUtils";
import { useMediaQuery } from "@mui/material";
import BasicModal from "../EditTicket/TicketModal";

const MainBoard = () => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width:768px)");
    const [loadInProgress, setLoadInProgress] = useState(true);
    const [loadUrgent, setLoadUrgent] = useState(true);
    const hasRun = useRef(false);

    const handleCreateTicket = () => {
        // navigate("/create-ticket");
        dispatch(setOpenCreateModal(true));
    };
    const { involveTickets, ticketStatusMap, priorityMapId, workTypeMap, categoryTypeMap, assignedToPersonMap, assignedToGroupMap, reasonForNoQuoteMap, mistakeCodeMap, userEmailmap, ticketTitleMap, notificationList, customerNameList, emailsToNotifyMap } = useSelector(state => state.tickets);

    const [mistakeCodes, setMistakeCodes] = useState();
    const [reasonForNoQuote, setReasonForNoQuote] = useState();
    const [contactPoints, setContactPoints] = useState();
    const [ulListings, setUlListings] = useState();
    const [ticketStatus, setTicketStatus] = useState();
    const [categories, setCategories] = useState();
    const [priorities, setPriorities] = useState();
    const [workTypes, setWorkTypes] = useState();
    const [groupEmployeData, setGroupEmployeData] = useState();
    // const [customerName, setCustomerName] = useState([]);

    const convertJsonToOptions = (arr) => {
        return arr?.map(item => ({
            id: item?.ID,
            value: item?.NAME,
            label: item?.NAME,
            type: item?.TYPE,
            email: item?.TYPE == "employee" ? item?.EMAIL : "",
        }));
    };
    const convertToOptions = (arr) => {
        return arr?.map(item => ({
            id: item[0],
            value: item[1],
            label: item[1]
        }));
    };
    // const convertCustomerToOptions = (arr) => {
    //     return arr[0]?.map(item => ({
    //         id: item[0],
    //         value: item[1],
    //         label: item[1]
    //     }));
    // }
    useEffect(() => {
        if (!hasRun.current) {
            const fetchData = async () => {
                try {
                    const auth = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Fix: Authorization should be inside headers
                        }
                    }
                    const [
                        mistakeCodesResponse,
                        reasonForNoQuoteResponse,
                        contactPointsResponse,
                        ulListingsResponse,
                        ticketStatusResponse,
                        categoriesResponse,
                        prioritiesResponse,
                        workTypesResponse,
                        groupEmployeResponse,
                        // customerNameResponse,
                    ] = await Promise.all([
                        fetch(apiUrl.mistakeCode, auth),
                        fetch(apiUrl.reasonForNoCode, auth),
                        fetch(apiUrl.contactPoints, auth),
                        fetch(apiUrl.ulListingUrl, auth),
                        fetch(apiUrl.ticketStatusListing, auth),
                        fetch(apiUrl.categoriesListing, auth),
                        fetch(apiUrl.prioritiesListing, auth),
                        fetch(apiUrl.workTypesListing, auth),
                        fetch(apiUrl.groupEmployeList, auth),
                        // fetch(apiUrl.customerName),
                    ]);
                    setMistakeCodes(convertToOptions(await mistakeCodesResponse.json()));
                    setReasonForNoQuote(convertToOptions(await reasonForNoQuoteResponse.json()));
                    setContactPoints(convertToOptions(await contactPointsResponse.json()));
                    setUlListings(convertToOptions(await ulListingsResponse.json()));
                    setTicketStatus(convertToOptions(await ticketStatusResponse.json()));
                    setCategories(convertToOptions(await categoriesResponse.json()));
                    setPriorities(convertToOptions(await prioritiesResponse.json()));
                    setWorkTypes(convertToOptions(await workTypesResponse.json()));
                    setGroupEmployeData(convertJsonToOptions(await groupEmployeResponse.json()));
                    // setCustomerName(convertCustomerToOptions(await customerNameResponse.json()));
                    dispatch(fetchCustomerNameData());
                    dispatch(fetchTicketStatus());
                    dispatch(fetchEmailsToNotifyMap());
                    dispatch(setIsLoading(false));
                    hasRun.current = true
                } catch (error) {
                    console.error("Error fetching dropdown data: ", error);
                }
            };
            fetchData();
        }
    }, []);
    const mapDataToId = (title, dataList) => {
        const temp = {};
        if (title == "assignedPerson" || title == "assignedGroup") {
            for (let i = 0; i < dataList?.length; i++) {
                if ((title == "assignedGroup" && dataList[i]?.type == "group") || title == "assignedPerson" && dataList[i]?.type == "employee") {
                    temp[dataList[i]?.id] = dataList[i]?.value;
                }
            }
        } else if (title == "ticketTitleMap") {
            for (let i = 0; i < dataList?.length; i++) {
                temp[dataList[i]?.TicketID] = dataList[i]?.Title;
            }
        } else if (title == "notificationList") {
            for (let i = 0; i < dataList?.length; i++) {
                if (!temp.hasOwnProperty(dataList[i]?.TicketID)) {
                    temp[dataList[i]?.TicketID] = dataList[i]?.Seen;
                }
            }
        } else if (title == "emailsToNotifyMap") {
            for (let i = 0; i < dataList?.length; i++) {
                if (!temp.hasOwnProperty(dataList[i]?.TicketID)) {
                    temp[dataList[i]?.TicketID] = [dataList[i]?.EmployeeID?assignedToPersonMap[dataList[i]?.EmployeeID]:assignedToGroupMap[dataList[i]?.GroupID]];
                } else {
                    temp[dataList[i]?.TicketID].push(dataList[i]?.EmployeeID?assignedToPersonMap[dataList[i]?.EmployeeID]:assignedToGroupMap[dataList[i]?.GroupID]);
                }
            }
        } 
        else {
            for (let i = 0; i < dataList?.length; i++) {
                if (title == "userEmailmap") {
                    temp[dataList[i]?.id] = dataList[i]?.email;
                } else {
                    temp[dataList[i]?.id] = dataList[i]?.value;
                }
            }
        }
        switch (title) {
            case "ticketStatus":
                dispatch(setTicketStatusMap(temp));
                break;
            case "priorities":
                dispatch(setPriorityMap(temp));
                break;
            case "workTypes":
                dispatch(setWorkTypeMap(temp));
                break;
            case "categories":
                dispatch(setCategoryMap(temp));
                break;
            case "assignedPerson":
                dispatch(setAssignedToPersonMap(temp));
                break;
            case "assignedGroup":
                dispatch(setAssignedToGroupMap(temp));
                break;
            case "reasonForNoQuote":
                dispatch(setReasonForNoQuoteMap(temp));
                break;
            case "mistakeCode":
                dispatch(setMistakeCodeMap(temp));
                break;
            case "customerNameList":
                dispatch(setCustomerNameMap(temp));
                break;
            case "userEmailmap":
                dispatch(setUserEmailMap(temp));
                break;
            case "ticketTitleMap":
                dispatch(setTicketTitleMap(temp));
                break;
            case "notificationList":
                dispatch(setNotificationStatusMap(temp));
                break;
            case "emailsToNotifyMap":
                dispatch(setEmailsToNotifyMapData(temp));
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        localStorage.setItem("mistakeCode", JSON.stringify(mistakeCodes));
        localStorage.setItem("reasonForNoQuote", JSON.stringify(reasonForNoQuote));
        localStorage.setItem("contactPoints", JSON.stringify(contactPoints));
        localStorage.setItem("ulListings", JSON.stringify(ulListings));
        localStorage.setItem("ticketStatus", JSON.stringify(ticketStatus));
        localStorage.setItem("categories", JSON.stringify(categories));
        localStorage.setItem("priorities", JSON.stringify(priorities));
        localStorage.setItem("workTypes", JSON.stringify(workTypes));
        localStorage.setItem("groupEmployeData", JSON.stringify(groupEmployeData));
        localStorage.setItem("customerNameList", JSON.stringify(customerNameList));
        mapDataToId("ticketStatus", ticketStatus);
        mapDataToId("priorities", priorities);
        mapDataToId("workTypes", workTypes);
        mapDataToId("categories", categories);
        mapDataToId("assignedPerson", groupEmployeData);
        mapDataToId("assignedGroup", groupEmployeData);
        mapDataToId("reasonForNoQuote", reasonForNoQuote);
        mapDataToId("mistakeCode", mistakeCodes);
        mapDataToId("customerNameList", customerNameList);
        mapDataToId("userEmailmap", groupEmployeData);
        mapDataToId("ticketTitleMap", involveTickets);
        mapDataToId("notificationList", notificationList);
        mapDataToId("emailsToNotifyMap", emailsToNotifyMap);
    }, [mistakeCodes, reasonForNoQuote, contactPoints, ulListings, ticketStatus, categories, priorities, workTypes, groupEmployeData, customerNameList, involveTickets, notificationList, emailsToNotifyMap]);

    useEffect(() => {
        let temp = {};
        for (let i = 0; i < involveTickets?.length; i++) {
            const ticketID = involveTickets[i]?.TicketStatusID;
            const status = ticketStatusMap[ticketID];
            const priorityId = involveTickets[i]?.PriorityID;
            const priorityStatus = priorityMapId[priorityId];
            let modifiedTicket = {
                ...involveTickets[i],
                WorkTypeID: workTypeMap[involveTickets[i]?.WorkTypeID],
                PriorityID: priorityMapId[involveTickets[i]?.PriorityID],
                CategoryID: categoryTypeMap[involveTickets[i]?.CategoryID],
                AssignedToPersonID: assignedToPersonMap[involveTickets[i]?.AssignedToPersonID],
                AssignedToEmailID: userEmailmap[involveTickets[i]?.AssignedToPersonID],
                AssignedToGroupID: assignedToGroupMap[involveTickets[i]?.AssignedToGroupID],
                ReasonForNoQuoteID: reasonForNoQuoteMap[involveTickets[i]?.ReasonForNoQuoteID],
                MistakeCodeID: mistakeCodeMap[involveTickets[i]?.MistakeCodeID],
                TicketStatusID: ticketStatusMap[involveTickets[i]?.TicketStatusID],
                is_sample_available: involveTickets[i]?.is_sample_available ? "Yes" : "No",
                can_get_sample: involveTickets[i]?.can_get_sample == true ? "Yes" : "No",
                need_to_see_process: involveTickets[i]?.need_to_see_process == true ? "Yes" : "No",
                automated_handling_process: involveTickets[i]?.automated_handling_process == true ? "Yes" : "No",
                sun_lite_resistant_plastic: involveTickets[i]?.sun_lite_resistant_plastic == true ? "Yes" : "No",
                gamma_ray_sterilize: involveTickets[i]?.gamma_ray_sterilize == true ? "Yes" : "No",
                ul_listing_required: involveTickets[i]?.ul_listing_required?.join(", ")
            };
            if (status == "In Progress") {
                if (!temp[status]) {
                    temp[status] = [];
                }
                temp[status].push(modifiedTicket);
            }
            if (priorityStatus == "Normal" || priorityStatus == "Urgent") { // Check if the status exists
                if (!temp["priorityStatus"]) { // Initialize array if it doesn't exist
                    temp["priorityStatus"] = [];
                }
                temp["priorityStatus"].push(modifiedTicket);
                if (!temp["allData"]) {
                    temp["allData"] = [];
                }
                temp["allData"].push(modifiedTicket);
            }
            if (!temp["allData"]) {
                temp["allData"] = [];
            }
            const isTicketUnique = !temp["allData"].some(item => item.TicketID == modifiedTicket.TicketID);

            if (modifiedTicket.TicketID && !temp["allData"].some(item => item.TicketID === modifiedTicket.TicketID)) {
                temp["allData"].push(modifiedTicket);
            }
        }
        dispatch(setTicketModifyStatus(temp));
    }, [ticketStatusMap, involveTickets]);

    return (
        <>
            <div className="main_board">
                <div className="tickets_board">
                    <div className="ticket_items">
                        <div className="ticket_item_active">
                            <span className="item_text">All Tickets/Open Tickets</span>
                            <p className="tickets_text">Tickets in queue</p>
                        </div>
                        {/* <div className="ticket_item">
                            <span className="item_text">All Tickets/Open Tickets</span>
                            <p className="tickets_text">Queue B</p>
                        </div>
                        <div className="ticket_item">
                            <span className="item_text">All Tickets/Open Tickets</span>
                            <p className="tickets_text">Queue C</p>
                        </div> */}
                    </div>
                    <div className="btn_div">
                        <button className="create_btn"><div className="btn_content" onClick={handleCreateTicket}><CreateNewFolderOutlinedIcon className="add_icon" /> <span className="btn_text">Create Ticket</span></div></button>
                    </div>

                </div>
                <div className="table_div">
                    <FilterOptions />
                    <ActiveFilters />
                    {!isMobile && <InProgress title="IN PROGRESS" colorcode="#2ca248" statusTable="In Progress" loading={loadInProgress} setLoading={setLoadInProgress} />}
                    {!isMobile && <InProgress colorcode="red" statusTable="priorityStatus" loading={loadUrgent} setLoading={setLoadUrgent} />}
                    {isMobile && <InProgress statusTable="allData" loading={loadUrgent} setLoading={setLoadUrgent} />}
                    {/* <InProgress title="NORMAL" colorcode="white" statusTable="Normal" loading={loadNormal} setLoading={setLoadNormal}/> */}
                    {/* <InProgress title="IN REVIEW" colorcode="white" statusTable="In Review"/>
                    <InProgress title="Hold for Sales Response" colorcode="white" statusTable="Hold for Sales Response"/>
                    <InProgress title="COMPLETE" colorcode="white" statusTable="Complete"/> */}
                </div>
            </div>
        </>
    );
};
export default MainBoard;