import { useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "./index.css";
import RevisionTicketForm from "./RevisionTicketForm";
import CircularBackdrop from "./CircularBackdrop";

const RevisionTicket = () => {
    const {
        isLoading,
        isBackdropLoading
    } = useSelector(state => state.tickets);

    return (
        <>
            <div className="outer_form_edit">
                <div className="inner_edit">
                    <div className="edit_form">
                        <h1>Revision Ticket</h1>
                        {/* <button onClick={()=>{dispatch(setOpenRevisionModal(true));navigate("/"); dispatch(setOpenEditModal(false))}}>Create Revision</button> */}
                        {isLoading ? <Box sx={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </Box> :
                            <>
                                {/* <p className="ticket_createdby_text">Created by &nbsp; &nbsp;<span className="user_text">{ticketData?.CreatedBy && assignedToPersonMap[ticketData?.CreatedBy]}</span>&nbsp; &nbsp;&nbsp; &nbsp; Assigned to &nbsp; &nbsp;<span className="user_text">{ticketData?.AssignedToGroupID ? assignedToGroupMap[ticketData?.AssignedToGroupID] : assignedToPersonMap[ticketData?.AssignedToPersonID]}</span></p> */}
                                <RevisionTicketForm /></>}
                    </div>
                    {
                        isBackdropLoading && <CircularBackdrop />
                    }
                </div>
                {/* <div className="comments">
                    <TicketHeirarchy />
                </div> */}
            </div>
        </>
    );
};
export default RevisionTicket;