import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useSelector } from 'react-redux';
import convertIntoDateHour from './convertIntoDateHour';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

const UserComments = () => {
    const [mentions, setMentions] = useState([]);
    const { assignedToPersonMap, commentList, groupEmployeData } = useSelector((state) => state.tickets);
    useEffect(() => {
        const temp = {};
        for (let i = 0; i < groupEmployeData?.length; i++) {
            temp[`<${groupEmployeData[i].type=="employee"?"EmployeeID":"GroupID"}=${groupEmployeData[i].id}>`] = `@${groupEmployeData[i].label}`
        }
        setMentions(temp);
    }, [groupEmployeData]);

    const formatCommentWithMentions = (comment) => {
        return comment.replace(/<[^>]+>/g, (match) => {
            return mentions[match]
                ? `<span class="user_mentions">${mentions[match]}</span>`
                : match;
        });
    };
    return (
        <>
            {/* <p className="current_day">{day_posted}</p> */}
            {
                commentList?.length > 0 ? commentList?.map((item, key) => {
                    return (
                        <div className="user_comments_container" key={item?.CommentID}>
                            <div className="user_icon_details">
                                <AccountCircleOutlinedIcon sx={{ color: "white", height: "40px", width: "40px" }} />
                            </div>
                            <div className="user_comment_details">
                                <span className="user_name">{assignedToPersonMap[item?.EmployeeID]}</span>
                                <br></br>
                                <div className="user_comment_text" dangerouslySetInnerHTML={{ __html: formatCommentWithMentions(item?.Content) }} />
                                {/* <span className="user_comment_text">{formatCommentWithMentions(item?.Content)}</span> */}
                                <br></br>
                                <span className="comment_date">{convertIntoDateHour(item?.CreatedAt)}</span>
                            </div>
                        </div>
                    );
                }) :
                    <Box sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", color: "white" }}>
                        <p>
                            No Comments Available
                        </p>
                    </Box>
            }
        </>
    );
}

export default UserComments;