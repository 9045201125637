import * as React from 'react';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal } from '../../actions/ticketActions';
import FeedbackForm from './FeedbackForm';

export default function FeedbackModal() {
    const dispatch = useDispatch();
    const { feedbackModal } = useSelector(state => state.tickets);
    const handleClose = () => dispatch(setFeedbackModal(false));

    return (
        <div>
            <Modal
                open={feedbackModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // overflow: 'auto', 
                }}
            >
                {/* <Box
                    sx={{
                        width: '80%',        // Adjust width as needed
                        // maxHeight: '90vh',    
                        // overflowY: 'auto',    
                        // bgcolor: "#ef6e35",
                        p: 4,
                        boxShadow: 24,
                    }}
                > */}
                    <FeedbackForm />
                {/* </Box> */}
            </Modal>
        </div>
    );
}
