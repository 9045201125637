const HeirarchyJson =
{
    "ticket_id": 2,
    descendants: [
        {ticketId: 1, des_title:"OEM Request"},
        {ticketId: 3, des_title:"OEM Request Name"}, 
        {ticketId: 4, des_title:"OEM Request Check"},
        {ticketId: 5, des_title:"OEM Request"},
        {ticketId: 6, des_title:"OEM Request"},
        {ticketId: 7, des_title:"OEM Request"},
        {ticketId: 8, des_title:"OEM Request"},
        {ticketId: 8, des_title:"OEM Request"},
    ],
    ancestor: [{ticketId: 10, des_title:"OEM Request Check"}]
}
export default HeirarchyJson;