import commonUrl from "./commonUrl";
const apiUrl = {
    mistakeCode: `${commonUrl.baseUrl}/dropdowns/mistake_codes`,
    reasonForNoCode: `${commonUrl.baseUrl}/dropdowns/reason_for_no_quote`,
    contactPoints: `${commonUrl.baseUrl}/dropdowns/contact_points`,
    ulListingUrl: `${commonUrl.baseUrl}/dropdowns/ul_listings`,
    ticketStatusListing: `${commonUrl.baseUrl}/dropdowns/ticket_status`,
    categoriesListing: `${commonUrl.baseUrl}/dropdowns/categories`,
    prioritiesListing: `${commonUrl.baseUrl}/dropdowns/priorities`,
    workTypesListing: `${commonUrl.baseUrl}/dropdowns/work_types`,
    groupEmployeList: `${commonUrl.baseUrl}/group-employee-list`,
    createTicket: `${commonUrl.baseUrl}/tickets`,
    involvingTickets: `${commonUrl.baseUrl}/rfqalltickets`,
    customerName: `${commonUrl.baseUrl}/dropdowns/customer_names`,
    editTicket: `${commonUrl.baseUrl}/update-ticket`,
    generateUploadUrlTicket: `${commonUrl.baseUrl}/generate-upload-url-ticket`,
    generateUploadUrlTemp: `${commonUrl.baseUrl}/generate-upload-url-temp`,
    getAttachment: (ticketId)=> `${commonUrl.baseUrl}/attachments?ticketID=${ticketId}`,
    downloadAttachment: (ticketId, fileName)=> `${commonUrl.baseUrl}/generate-download-link?ticketID=${ticketId}&filename=${fileName}`,
    groupEmployeMapUrl:  `${commonUrl.baseUrl}/employee-groups`,
    deleteFile: (attachmentId)=> `${commonUrl.baseUrl}/del-attachments/${attachmentId}`,
    emailsToNotifyUrl: (ticketId)=>`${commonUrl.baseUrl}/emailstonotify?ticketID=${ticketId}`,
    addComments: `${commonUrl.baseUrl}/add-comment`,
    getComments: (ticketId)=>`${commonUrl.baseUrl}/getticketcomments?ticketID=${ticketId}`,
    loginUser: `${commonUrl.baseUrl}/loginuser`,
    protectedUser:  `${commonUrl.baseUrl}/protected`,
    getHistory: (ticketId)=>`${commonUrl.baseUrl}/history?ticketID=${ticketId}`,
    createRevision: `${commonUrl.baseUrl}/revisions-ticket`,
    ticketLineage: (ticketId)=>`${commonUrl.baseUrl}/ticket-lineage/${ticketId}`,
    getNotificationList: `${commonUrl.baseUrl}/notifications`,
    markNotificationCheck: (ticketId)=>`${commonUrl.baseUrl}/notifications/ticket/${ticketId}`,
    feedback: `${commonUrl.baseUrl}/feedback`,
    emailsToNotifyMapUrl: `${commonUrl.baseUrl}/rfqtickets/emailstonotify`,
    generateDownloadLink: (filename) => `${commonUrl.baseUrl}/generate-download-link-temp?filename=${filename}`
};

export default apiUrl;
