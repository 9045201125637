import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Navbar1 from ".src/components/navbar/Navbar1";
import Navbar1 from "./components/navbar/Navbar1";
// import Dashboard from "./components/dashboard/Dashboard";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardTable from "./components/dashboard/DashboardTable";
import CreateTicket from "./components/TicketOperations/CreateTicket";
import TicketForm from "./components/CreateTicket/TicketForm";
import SimpleGrid from "./components/dashboard/checkFilter";
import { useEffect } from "react";
import LoginPage from "./components/login/LoginPage";
import { useDispatch } from "react-redux";
import { userProtected } from "./actions/ticketActions";
import { PDFPreview } from "./components/TicketOperations/Test";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userProtected());
  }, []);

  const useHideUnimportantErrors = () => {
    useEffect(() => {
      function hideError(e) {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
      }

      window.addEventListener('error', hideError)
      return () => {
        window.addEventListener('error', hideError)
      }
    }, [])
  };

  useHideUnimportantErrors();
  return (
    <Router>
      <Routes>
        {/* <Route path="/login" element= {<Login />}/> */}
        <Route path="/create-ticket-temp" element={<TicketForm />} />
        <Route path="/dashboard" element={

          <div>
            <div className="outer_div">
              <Navbar1 />
              <Dashboard />
            </div>
            {/* <Navbar open={open} handleDrawerOpen={handleDrawerOpen} />
          <PersistentDrawer open={open} handleDrawerClose={handleDrawerClose} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes> */}
          </div>}
        />
      </Routes>
      <Routes>
        <Route path="/" element={<DashboardTable />} />
        <Route path="/preview" element={<PDFPreview />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path={`/edit-ticket`} element={<DashboardTable />} />
        <Route path="/create-ticket" element={<CreateTicket />} />
        {/* <Route path="/edit-ticket/?ticketId=${}" element={<EditTicket />} /> */}
        <Route path="/check" element={<SimpleGrid />} />
      </Routes>
    </Router>

  );
}

export default App;
