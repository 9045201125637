import { components } from "react-select";
const CustomMultiValue = (props) => {
    return (
      <components.MultiValue {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
          {props.data.label}
        </div>
      </components.MultiValue>
    );
  };
  export default CustomMultiValue;
