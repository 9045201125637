import { useDispatch, useSelector } from "react-redux";
import Comments from "./Comments";
import EditTicketForm from "./EditTicketForm";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "./index.css";
import { fetchLineageTicket, markNotificationStatus, setOpenEditModal, setOpenRevisionModal, setParentTicket } from "../../actions/ticketActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HeirarchyJson from "./HeirarchyJson";
import { useEffect } from "react";
import CircularBackdrop from "./CircularBackdrop";
const isMobile = window.innerWidth <= 768;

const EditTicket = () => {
    const {
        ticketData,
        assignedToPersonMap,
        assignedToGroupMap,
        isLoading,
        emailToNotifyLoading,
        notificationStatusMap,
        isBackdropLoading,
    } = useSelector(state => state.tickets);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const ticketId = searchParams.get("ticketId");
    useEffect(() => {
        if (notificationStatusMap[ticketId] == false) {
            dispatch(markNotificationStatus(ticketId));
        }
    }, [ticketId]);

    return (
        <>
            <div className="outer_form_edit">
                <div className="back_btn_div">
                    <div className="back_btn" onClick={() => { navigate("/"); dispatch(setOpenEditModal(false)) }}>
                        <ArrowBackIcon sx={{ color: "white" }} />
                    </div>
                </div>
                <div className="inner_edit">
                    <div className="edit_form">
                        <div className="revision_div">
                            <h1>Edit Ticket</h1>
                            <button className="revision_btn" onClick={() => { dispatch(setParentTicket(ticketId)); dispatch(setOpenRevisionModal(true)); navigate("/"); dispatch(setOpenEditModal(false)) }}>Create Revision</button>
                        </div>
                        {!isLoading && !emailToNotifyLoading && <p className="ticket_createdby_text">
                            Created by &nbsp; &nbsp;<span className="user_text">{ticketData?.CreatedBy && assignedToPersonMap[ticketData?.CreatedBy]}</span>
                            &nbsp; &nbsp;&nbsp; &nbsp; <br className="next_line"></br> <br className="next_line"></br>Assigned to &nbsp; &nbsp;<span className="user_text">{ticketData?.AssignedToGroupID ? assignedToGroupMap[ticketData?.AssignedToGroupID] : assignedToPersonMap[ticketData?.AssignedToPersonID]}</span></p>}
                        {/* </div> */}
                        {isLoading && emailToNotifyLoading ? <Box sx={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </Box> :
                            <div style={{ overflow: "hidden" }}>
                                <EditTicketForm /></div>}
                        {
                            isBackdropLoading && <CircularBackdrop />
                        }
                    </div>
                    <div className="comments" id="comments_mobile">
                        {isMobile && <Comments />}
                    </div>
                </div>
                <div className="comments" id="comments_desktop">
                    {!isMobile && <Comments />}
                </div>
            </div>
        </>
    );
};
export default EditTicket;