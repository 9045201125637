import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; // Use named import
import { combineReducers } from 'redux';
import ticketReducer from '../reducers/ticketReducer';

const rootReducer = combineReducers({
    tickets: ticketReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
