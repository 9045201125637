import React from "react";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";

const TextBgWithAttachment = ({ value }) => (
    <span style={{ 
        backgroundColor: "white", 
        color: "black", 
        borderRadius: "7px", 
        padding: "1px 4px", 
        display: "inline-flex", 
        alignItems: "center", 
        gap: "4px",  // Spacing between icon and text
        height:"20px",
        marginTop:"8px"
    }}>
        <AddLinkOutlinedIcon style={{ fontSize: "20px" }} />
        {value}
    </span>
);

export default TextBgWithAttachment;
