import CreateTicketForm from "./CreateTicketForm";
import RestoreIcon from '@mui/icons-material/Restore';
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { setOpenCreateModal, setTicketData } from "../../actions/ticketActions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularBackdrop from "../EditTicket/CircularBackdrop";

const CreateTicket = () => {
    const navigate = useNavigate();
    const {
        isBackdropLoading,
    } = useSelector(state => state.tickets);
    const [draftData, setDraftData] = useState(JSON.parse(localStorage.getItem("draftData")));
    const [draftAvailable, setDraftAvailable] = useState(localStorage.getItem("draftAvailable"));

    useEffect(() => {
        setDraftData(JSON.parse(localStorage.getItem("draftData")));
        setDraftAvailable(localStorage.getItem("draftAvailable"));
    }, [localStorage])
    const dispatch = useDispatch();
    const handleLoadDraft = () => {
        dispatch(setTicketData(JSON.parse(localStorage.getItem("draftData"))));
        localStorage.setItem("draftAvailable", "false");
        setDraftAvailable("false");
    }
    useEffect(() => {
        return () => {
            dispatch(setTicketData([]));
        }
    }, []);
    return (
        <>
            <div className="outer_form">
                <div className="inner_form">
                    <div className="back_btn" onClick={() => { navigate("/"); dispatch(setOpenCreateModal(false)) }}>
                        <ArrowBackIcon sx={{ color: "white" }} />
                    </div>
                    <div className="form_header">
                        <h1>Create Ticket</h1>
                        {
                            Object.keys(draftData || {}).length !== 0 && draftAvailable == "true" &&
                            <div className="btn_div">
                                <button className="create_btn" onClick={handleLoadDraft}><div className="btn_content" ><RestoreIcon /> <span className="btn_text">Load Draft</span></div></button>
                            </div>
                        }
                    </div>
                    <CreateTicketForm />
                    {
                        isBackdropLoading && <CircularBackdrop />
                    }
                </div>
            </div>
        </>
    );
};
export default CreateTicket;