import {
    SET_INVOLVE_TICKETS,
    SET_TICKET_STATUS,
    TICKET_STATUS_FILTER,
    TICKET_STATUS_MAP,
    PRIORITY_MAP,
    WORK_TYPE_MAP,
    CATEGORY_TYPE_MAP,
    ASSIGNED_TO_PERSON_MAP,
    ASSIGNED_TO_GROUP_MAP,
    REASON_FOR_NO_QUOTE_MAP,
    MISTAKE_CODE_MAP,
    FILTERED_DATA_FOR_TABLE,
    FILTER_OPTIONS,
    OPEN_EDIT_MODAL,
    MISTAKE_CODE,
    REASON_FOR_NO_QUOTE,
    CONTACT_POINTS,
    UL_LISTINGS,
    TICKET_STATUS,
    CATEGORIES,
    PRIORITIES,
    WORK_TYPE,
    GROUP_EMPLOYEE,
    TICKET_DATA,
    INVOLVE_TICKET_MAP,
    OPEN_CREATE_MODAL,
    ROW_DATA_APPLIED,
    FEEDBACK_MODAL,
    CUSTOMER_NAME_MAP,
    CUSTOMER_NAME,
    EDIT_TICKET,
    ACTIVE_FILTERS,
    SELECTED_USERS,
    COMPLETE_DATA,
    UPLOAD_URL_TICKET,
    UPLOAD_URL_TEMP,
    GET_ATTACHMENT,
    DOWNLOAD_ATTACHMENT,
    GROUP_TO_EMPLOYE_MAP,
    GROUP_EMPLOYEE_RESPONSE,
    IS_LOADING,
    QUICK_FILTER_TEXT,
    OPEN_REVISION_MODAL,
    DELETE_FILE,
    EMAILS_TO_NOTIFY,
    EMAIL_TO_NOTIFY_LOADING,
    FILE_TO_REMOVE,
    ADD_COMMENTS,
    GET_COMMENTS,
    COMMENT_IS_LOADING,
    LOGIN_PAYLOAD,
    LOGIN_TOKEN,
    IS_PROTECTED,
    IS_LOGGEDIN,
    TICKET_HISTORY,
    IS_HISTORY_LOADING,
    USER_EMAIL_MAP,
    REVISION_TICKET,
    PARENT_TICKET,
    TICKET_TITLE_MAP,
    LINEAGE_DATA,
    NOTIFICATIONS_LIST,
    MARK_NOTIFICATION,
    NOTIFICATION_STATUS_MAP,
    FEEDBACK_RESULT,
    EMAILS_TO_NOTIFY_MAP,
    EMAILS_TO_NOTIFY_MAP_DATA,
    DOWNLOAD_FILE_TEMP,
    ATTACHMENT_LOADING,
    PUTSIGNED_SUCCESS,
    IS_BACKDROP_LOADING,
} from '../actions/types';

const initialState = {
    involveTickets: [],
    ticketModifyStatus: {},
    ticketStatusFilter: {},
    ticketStatusMap: {},
    priorityMapId: {},
    workTypeMap: {},

    categoryTypeMap: {},
    assignedToPersonMap: {},
    assignedToGroupMap: {},
    reasonForNoQuoteMap: {},
    mistakeCodeMap: {},
    filteredDataForTable: {},
    filterOptions: [{
        "id": "1234",
        "name": "",
        "symbol_name": ""
    }],
    openEditModal: false,
    mistakeCode: [],
    reasonForNoQuote: [],
    contactPoints: [],
    ulListings: [],
    ticketStatus: [],
    categories: [],
    priorities: [],
    workTypes: [],
    groupEmployeData: [],
    ticketData: {},
    involveTicketMap: {},
    openCreateModal: false,
    rowDataApplied: [],
    feedbackModal: false,
    customerNameMap: {},
    customerNameList: [],
    editTicket: {},
    activeFilters: [],
    selectedUsers: [],
    completeData: [],
    uploadUrlTicket: {},
    uploadUrlTemp: {},
    attachmentResponse: [],
    downloadAttachmentResponse: {},
    groupToEmployeMap: {},
    groupEmployeeResponse: {},
    isLoading: true,
    quickFilterText: "",
    openRevisionModal: false,
    deleteAttachmentRes: {},
    emailsToNotifyResponse: [],
    emailToNotifyLoading: true,
    fileToRemove: "",
    createCommentResponse: {},
    commentList: [],
    isCommentLoading: false,
    loginPayload: {},
    loginToken: {},
    isProtected: {},
    isLoggedIn: false,
    ticketHistory: [],
    historyLoading: true,
    userEmailmap: {},
    revisionData: {},
    parentTicket: "",
    ticketTitleMap: {},
    lineageData: {},
    notificationList: [],
    markNotification: {},
    notificationStatusMap: {},
    feedbackResult: {},
    emailsToNotifyMap: {},
    emailsToNotifyMapData: {},
    downloadFileTemp: {},
    attachmentLoading: false,
    putSignedSuccess: {},
    isBackdropLoading: false,
};

const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INVOLVE_TICKETS:
            return {
                ...state,
                involveTickets: action.payload,
            };
        case SET_TICKET_STATUS:
            return {
                ...state,
                ticketModifyStatus: action.payload,
            }
        case TICKET_STATUS_FILTER:
            return {
                ...state,
                ticketStatusFilter: action.payload,
            }
        case TICKET_STATUS_MAP:
            return {
                ...state,
                ticketStatusMap: action.payload,
            }
        case PRIORITY_MAP:
            return {
                ...state,
                priorityMapId: action.payload,
            }
        case WORK_TYPE_MAP:
            return {
                ...state,
                workTypeMap: action.payload,
            }
        case CATEGORY_TYPE_MAP:
            return {
                ...state,
                categoryTypeMap: action.payload,
            }
        case ASSIGNED_TO_PERSON_MAP:
            return {
                ...state,
                assignedToPersonMap: action.payload,
            }
        case ASSIGNED_TO_GROUP_MAP:
            return {
                ...state,
                assignedToGroupMap: action.payload,
            }
        case REASON_FOR_NO_QUOTE_MAP:
            return {
                ...state,
                reasonForNoQuoteMap: action.payload,
            }
        case MISTAKE_CODE_MAP:
            return {
                ...state,
                mistakeCodeMap: action.payload,
            }
        case FILTERED_DATA_FOR_TABLE:
            return {
                ...state,
                filteredDataForTable: action.payload,
            }
        case FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: action.payload,
            }
        case OPEN_EDIT_MODAL:
            return {
                ...state,
                openEditModal: action.payload,
            }
        case OPEN_CREATE_MODAL:
            return {
                ...state,
                openCreateModal: action.payload,
            }
        case MISTAKE_CODE:
            return {
                ...state,
                mistakeCodes: action.payload,
            }
        case REASON_FOR_NO_QUOTE:
            return {
                ...state,
                reasonForNoQuote: action.payload,
            }
        case CONTACT_POINTS:
            return {
                ...state,
                contactPoints: action.payload,
            }
        case UL_LISTINGS:
            return {
                ...state,
                ulListings: action.payload,
            }
        case TICKET_STATUS:
            return {
                ...state,
                ticketStatus: action.payload,
            }
        case CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            }
        case PRIORITIES:
            return {
                ...state,
                priorities: action.payload,
            }
        case WORK_TYPE:
            return {
                ...state,
                workTypes: action.payload,
            }
        case GROUP_EMPLOYEE:
            return {
                ...state,
                groupEmployeData: action.payload,
            }
        case TICKET_DATA:
            return {
                ...state,
                ticketData: action.payload,
            }
        case INVOLVE_TICKET_MAP:
            return {
                ...state,
                involveTicketMap: action.payload,
            }
        case ROW_DATA_APPLIED:
            return {
                ...state,
                rowDataApplied: action.payload,
            }
        case FEEDBACK_MODAL:
            return {
                ...state,
                feedbackModal: action.payload,
            }
        case CUSTOMER_NAME_MAP:
            return {
                ...state,
                customerNameMap: action.payload,
            }
        case CUSTOMER_NAME:
            return {
                ...state,
                customerNameList: action.payload,
            }
        case EDIT_TICKET:
            return {
                ...state,
                editTicket: action.payload,
            }
        case ACTIVE_FILTERS:
            return {
                ...state,
                activeFilters: action.payload,
            }
        case SELECTED_USERS:
            return {
                ...state,
                selectedUsers: action.payload,
            }
        case COMPLETE_DATA:
            return {
                ...state,
                completeData: action.payload,
            }
        case UPLOAD_URL_TICKET:
            return {
                ...state,
                uploadUrlTicket: action.payload,
            }
        case UPLOAD_URL_TEMP:
            return {
                ...state,
                uploadUrlTemp: action.payload,
            }
        case GET_ATTACHMENT:
            return {
                ...state,
                attachmentResponse: action.payload,
            }
        case DOWNLOAD_ATTACHMENT:
            return {
                ...state,
                downloadAttachmentResponse: action.payload,
            }
        case GROUP_EMPLOYEE_RESPONSE:
            return {
                ...state,
                groupEmployeeResponse: action.payload,
            }
        case GROUP_TO_EMPLOYE_MAP:
            return {
                ...state,
                groupToEmployeMap: action.payload,
            }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case QUICK_FILTER_TEXT:
            return {
                ...state,
                quickFilterText: action.payload,
            }
        case OPEN_REVISION_MODAL:
            return {
                ...state,
                openRevisionModal: action.payload,
            }
        case DELETE_FILE:
            return {
                ...state,
                deleteAttachmentRes: action.payload,
            }
        case EMAILS_TO_NOTIFY:
            return {
                ...state,
                emailsToNotifyResponse: action.payload,
            }
        case EMAIL_TO_NOTIFY_LOADING:
            return {
                ...state,
                emailToNotifyLoading: action.payload,
            }
        case FILE_TO_REMOVE:
            return {
                ...state,
                fileToRemove: action.payload,
            }
        case ADD_COMMENTS:
            return {
                ...state,
                createCommentResponse: action.payload,
            }
        case GET_COMMENTS:
            return {
                ...state,
                commentList: action.payload,
            }
        case COMMENT_IS_LOADING:
            return {
                ...state,
                isCommentLoading: action.payload,
            }
        case LOGIN_PAYLOAD:
            return {
                ...state,
                loginPayload: action.payload,
            }
        case LOGIN_TOKEN:
            return {
                ...state,
                loginToken: action.payload,
            }
        case IS_PROTECTED:
            return {
                ...state,
                isProtected: action.payload,
            }
        case IS_LOGGEDIN:
            return {
                ...state,
                isLoggedIn: action.payload,
            }
        case TICKET_HISTORY:
            return {
                ...state,
                ticketHistory: action.payload,
            }
        case IS_HISTORY_LOADING:
            return {
                ...state,
                historyLoading: action.payload,
            }
        case USER_EMAIL_MAP:
            return {
                ...state,
                userEmailmap: action.payload,
            }
        case REVISION_TICKET:
            return {
                ...state,
                revisionData: action.payload,
            }
        case PARENT_TICKET:
            return {
                ...state,
                parentTicket: action.payload,
            }
        case TICKET_TITLE_MAP:
            return {
                ...state,
                ticketTitleMap: action.payload,
            }
        case LINEAGE_DATA:
            return {
                ...state,
                lineageData: action.payload,
            }
        case NOTIFICATIONS_LIST:
            return {
                ...state,
                notificationList: action.payload,
            }
        case MARK_NOTIFICATION:
            return {
                ...state,
                markNotification: action.payload,
            }
        case NOTIFICATION_STATUS_MAP:
            return {
                ...state,
                notificationStatusMap: action.payload,
            }
        case FEEDBACK_RESULT:
            return {
                ...state,
                feedbackResult: action.payload,
            }
        case EMAILS_TO_NOTIFY_MAP:
            return {
                ...state,
                emailsToNotifyMap: action.payload,
            }
        case EMAILS_TO_NOTIFY_MAP_DATA:
            return {
                ...state,
                emailsToNotifyMapData: action.payload,
            }
        case DOWNLOAD_FILE_TEMP:
            return {
                ...state,
                downloadFileTemp: action.payload,
            }
        case ATTACHMENT_LOADING:
            return {
                ...state,
                attachmentLoading: action.payload,
            }
        case PUTSIGNED_SUCCESS:
            return {
                ...state,
                putSignedSuccess: action.payload,
            }
        case IS_BACKDROP_LOADING:
            return {
                ...state,
                isBackdropLoading: action.payload,
            }
        default:
            return state;
    }
};

export default ticketReducer;
