import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';

  const convertToOptions = (arr) => {
    return arr?.map(item => ({
      id: item[0],
      value: item[1],
      label: item[1]
    }));
  };

  const convertJsonToOptions = (arr) => {
    return arr?.map(item => ({
      id: item?.ID,
      value: item?.NAME,
      label: item?.NAME,
      type: item?.TYPE,
      icon: item?.TYPE == "group" ? <GroupIcon /> : <PersonIcon />,
      email: item?.EMAIL,
    }));
  };
  export {convertToOptions, convertJsonToOptions};