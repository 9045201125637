import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import MultiSelectDropdown from "../TicketOperations/MultiselectDropDown";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import "./index.css";
import RichText from '../TicketOperations/RichText';
import DropdownControllers from "../dashboard/DropdownControllers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import CreatableSelect from "react-select/creatable";
import pdfIcon from "../../assets/images/pdfIcon.png";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { automatedHandling, cableContactPointList, gammaRays, isSampleAvailable, processCableList, pullForceNeeded, seeProcess, sunLightResistancePlastic, UlListing, UlListingMapping } from "../TicketOperations/DropdownOptions";
import {
  setCategories,
  setContactPoints,
  setGroupEmployeData,
  setMistakeCodes,
  setPriorities,
  setReasonForNoQuote,
  setTicketStatus,
  setUlListings,
  setWorkTypes,
  uploadUrlTicketEpic,
  putSignedUrl,
  setOpenRevisionModal,
  createRevisionTicket,
  fetchTicketStatus,
  fetchGroupEmployeData,
  setAttachmentLoading,
  setPutSignedSuccess,
  setDownloadFileTemp,
  setUploadUrlTemp,
  setIsBackdropLoading,
  downloadAttachment,
  uploadUrlTempEpic
} from "../../actions/ticketActions";
import { useNavigate } from "react-router-dom";
import { showToastError } from "../common/toast";
import { CircularProgress, Skeleton } from "@mui/material";
const isMobile = window.innerWidth <= 768;

const initialValues = {
  title: "",
  workType: "",
  priority: "",
  category: "",
  // assigned_product_dsigner: "",
  ticketStatus: "",
  description: "",
  customerName: "",
  assignedPartNumber: "",
  quotePartRequired: 0,
  orderPartRequired: 0,
  wordSpecsRequired: 0,
  cadDrawingsRequired: 0,
  footage: "",
  emailsToNotify: [],
  attachments: [],
};

const RevisionTicketForm = () => {
  const { register, handleSubmit, control, reset, watch, getValues, formState: { errors, dirtyFields } } = useForm();
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [fileData, setFileData] = useState();
  const [inputValue, setInputValue] = useState("");
  const [keywordsValue, setKeywordsValue] = useState([]);
  const [urls, setUrls] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    mistakeCodes,
    reasonForNoQuote,
    contactPoints,
    ulListings,
    ticketStatus,
    categories,
    priorities,
    workTypes,
    groupEmployeData,
    ticketData,
    workTypeMap,
    priorityMapId,
    categoryTypeMap,
    assignedToPersonMap,
    assignedToGroupMap,
    reasonForNoQuoteMap,
    mistakeCodeMap,
    ticketStatusMap,
    customerNameList,
    uploadUrlTicket,
    attachmentResponse,
    editTicket,
    openRevisionModal,
    parentTicket,
    emailsToNotifyResponse,
    customerNameMap,
    attachmentLoading,
    putSignedSuccess,
    uploadUrlTemp,
    downloadFileTemp
  } = useSelector(state => state.tickets);
  const categoryValue = watch("category");
  const isSampleValue = watch("isSample");
  const pullForceNeed = watch("pullForceNeed");

  useEffect(() => {
    if (attachmentResponse) {
      let temp = [...attachmentsList];
      for (let i = 0; i < attachmentResponse?.length; i++) {
        temp.push(attachmentResponse[i]?.FileUrl);
      }
      setAttachmentsList(temp);
    }
  }, [attachmentResponse]);

  const modifyListToString = (arr) => arr?.map(item => item.value).join(", ");

  const onSubmit = (data) => {
    if (keywordsValue?.length <= 0) return;
    dispatch(setIsBackdropLoading(true));
    let ulLlistingModifiedList, cableContactPointsModifiedList = modifyListToString(data?.cableContactPoints, ""), processCableModifiedList = modifyListToString(data?.processAapplication, "");
    if (data?.ulListingRequired) {
      ulLlistingModifiedList = data?.ulListingRequired?.map((item) => { return item?.id; });
    }

    const groupEmployeId = data?.emailsToNotify?.map((item) => {
      // return item?.value;
      return item?.type === "group" ? { "GroupID": item?.id } : { "EmployeeID": item?.id };
    });
    // const transformedData = EditPayload(modifiedData);
    const ticketData = {
      source_ticket_id: parentTicket,
      Title: data?.title,
      WorkTypeID: Number(data?.workType?.id),
      PriorityID: Number(data?.priority?.id),
      CategoryID: Number(data?.category?.id),
      is_sample_available: data?.isSample?.value === "Yes" ? true : false,
      need_to_see_process: data?.isProcess?.value === "Yes" ? true : false,
      process_cable_used_in: processCableModifiedList,
      specific_pull_force_required: data?.pullForceNeed?.value === "Yes" ? true : false,
      pull_force_value: Number(data?.newColumnPullForce),
      automated_handling_process: data?.automatedHandling?.value === "Yes" ? true : false,
      automated_handling_details: data?.newColumnAutomated,
      sun_lite_resistant_plastic: data?.sunLightRes?.value === "Yes" ? true : false,
      contact_points_with_elements: cableContactPointsModifiedList,
      gamma_ray_sterilize: data?.gammaRaysSterilize?.value === "Yes" ? true : false,
      ulListingRequired: ulLlistingModifiedList,
      TicketStatusID: Number(data?.ticketStatus?.id),
      Description: data?.description,
      CustomerName: data?.customerName?.id,
      AssignedPartNumber: modifyListToString(keywordsValue),
      QuotePartRequired: Number(data?.quotePartRequired),
      OrderPartRequired: Number(data?.orderPartRequired),
      WordSpecsRequired: Number(data?.wordSpecsRequired),
      CADDrawingsRequired: Number(data?.cadDrawingsRequired),
      footage: Number(data?.footage),
      emailsToNotify: groupEmployeId,
      attachments: attachmentsList
    };
    dispatch(createRevisionTicket(ticketData));
    if (attachmentsList?.length > 0) {
      // dispatch(editTicketData({ ...transformedData, TicketID: ticketData?.TicketID, attachments: attachmentsList }));
    } else {
      // dispatch(editTicketData({ ...transformedData, TicketID: ticketData?.TicketID }));
    }
    navigate("/");
  };

  useEffect(() => {
    const getParsedItem = (key, isJson = true) => {
      const item = localStorage?.getItem(key);
      if (!item) return isJson ? [] : '';
      try {
        return isJson ? JSON.parse(item) : item;
      } catch (e) {
        console.error(`Error parsing ${key}:`, e);
        return isJson ? [] : '';
      }
    };

    dispatch(setMistakeCodes(getParsedItem("mistakeCode") || []));
    dispatch(setReasonForNoQuote(getParsedItem("reasonForNoQuote") || []));
    dispatch(setContactPoints(getParsedItem("contactPoints") || []));
    dispatch(setUlListings(getParsedItem("ulListings") || []));
    dispatch(setTicketStatus(getParsedItem("ticketStatus", false) || []));
    dispatch(setCategories(getParsedItem("categories") || []));
    dispatch(setPriorities(getParsedItem("priorities") || []));
    dispatch(setWorkTypes(getParsedItem("workTypes") || []));
    dispatch(setGroupEmployeData(getParsedItem("groupEmployeData") || []));
  }, [localStorage]);

  useEffect(() => {
    localStorage.setItem("mistakeCode", JSON.stringify(mistakeCodes));
    localStorage.setItem("reasonForNoQuote", JSON.stringify(reasonForNoQuote));
    localStorage.setItem("contactPoints", JSON.stringify(contactPoints));
    localStorage.setItem("ulListings", JSON.stringify(ulListings));
    localStorage.setItem("ticketStatus", JSON.stringify(ticketStatus));
    localStorage.setItem("categories", JSON.stringify(categories));
    localStorage.setItem("priorities", JSON.stringify(priorities));
    localStorage.setItem("workTypes", JSON.stringify(workTypes));
    localStorage.setItem("groupEmployeData", JSON.stringify(groupEmployeData));
    localStorage.setItem("customerNameList", JSON.stringify(customerNameList));
  }, [mistakeCodes, reasonForNoQuote, contactPoints, ulListings, ticketStatus, categories, priorities, workTypes, groupEmployeData, customerNameList]);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        {/* Render an icon and the option label */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.data.icon && <span style={{ marginRight: "8px" }}>{props.data.icon}</span>}
          {props.data.label}
        </div>
      </components.Option>
    );
  };
  const convertStringToJsonArray = (str) => {
    // Remove the curly braces and split by comma
    const items = str?.replace(/[{}]/g, '')?.split(',');

    // Map each item to the required JSON structure
    const arr = items?.map(item => ({
      label: item,
      value: item,
      name: item, id: item
    }));
    return arr;
  };

  useEffect(() => {
    if (ticketData) {
      reset({
        title: ticketData.Title || "",
        workType: ticketData?.WorkTypeID && { id: ticketData?.WorkTypeID, value: workTypeMap[ticketData?.WorkTypeID], label: workTypeMap[ticketData?.WorkTypeID] },
        priority: ticketData?.PriorityID && { id: ticketData?.PriorityID, value: priorityMapId[ticketData?.PriorityID], label: priorityMapId[ticketData?.PriorityID] },
        category: ticketData?.CategoryID && { id: ticketData?.CategoryID, value: categoryTypeMap[ticketData?.CategoryID], label: categoryTypeMap[ticketData?.CategoryID] },
        ticketStatus: ticketData?.TicketStatusID && { id: ticketData?.TicketStatusID, value: ticketStatusMap[ticketData?.TicketStatusID], label: ticketStatusMap[ticketData?.TicketStatusID] },
        description: ticketData?.Description,
        CustomerName: convertStringToJsonArray(ticketData?.CustomerName),
        cableContactPoints: ticketData?.contact_points_with_elements?.split(", ")?.map((item) => { return { label: item, value: item } }),
        assignedPartNumber: ticketData?.AssignedPartNumber,
        quotePartRequired: ticketData?.QuotePartRequired,
        wordSpecsRequired: ticketData?.WordSpecsRequired,
        orderPartRequired: ticketData?.OrderPartRequired,
        cadDrawingsRequired: ticketData?.CADDrawingsRequired,
        footage: ticketData?.Footage,
        isSample: ticketData?.is_sample_available == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        isProcess: ticketData?.need_to_see_process == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        processApplication: ticketData?.process_cable_used_in?.split(", ")?.map(value => ({
          label: value,
          value: value
        })),
        // emailsToNotify: 
        automatedHandling: ticketData?.automated_handling_process == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        pullForceNeed: ticketData?.specific_pull_force_required == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        newColumnAutomated: ticketData?.automated_handling_details,
        newColumnPullForce: ticketData?.pull_force_value,
        sunLightRes: ticketData?.sun_lite_resistant_plastic == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        gammaRaysSterilize: ticketData?.gamma_ray_sterilize == true ? { id: "Yes", value: "Yes", label: "Yes" } : { id: "No", value: "No", label: "No" },
        ulListingRequired: ticketData?.ul_listing_required?.map(value => ({
          id: value,
          label: UlListingMapping[value],
          value: UlListingMapping[value]
        })),
        emailsToNotify: emailsToNotifyResponse?.map(value => ({
          id: value?.GroupID ? value?.GroupID : value?.EmployeeID,
          label: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
          value: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
          icon: value?.GroupID ? <GroupIcon /> : <PersonIcon />
        })) || []
      });
    }
  }, [ticketData, reset, ticketStatus, workTypeMap, assignedToGroupMap, UlListingMapping, assignedToPersonMap, priorityMapId, categoryTypeMap, emailsToNotifyResponse]);

  useEffect(() => {
    const file = fileData;
    if (file && uploadUrlTemp?.url) {
      dispatch(setAttachmentLoading(true));
      dispatch(putSignedUrl({
        url: uploadUrlTemp?.url,
        data: file
      }, dispatch))
    }
  }, [uploadUrlTemp]);
    useEffect(() => {
      if (downloadFileTemp?.download_url) {
        const pdfUrl = downloadFileTemp?.download_url;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = "_blank";
        link.download = downloadFileTemp?.download_url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, [downloadFileTemp]);

  useEffect(() => {
    if (putSignedSuccess?.code == "00000") {
      const temp = [...attachmentsList];
      if (uploadUrlTemp?.file_name)
        temp.push(uploadUrlTemp?.file_name);
      setAttachmentsList(temp);
      dispatch(setPutSignedSuccess({}));
    }
  }, [putSignedSuccess])

  useEffect(() => {
    if (openRevisionModal) {
      setAttachmentsList([]);
    }
  }, [openRevisionModal]);


  const handleUploadImage = (e) => {
    // dispatch(setAttachmentLoading(true));
    const file = e?.currentTarget?.files?.[0];
    const imageType = file?.type.split("/")[1];
    const payload = {
      "file_extension": imageType,
    }
    dispatch(uploadUrlTempEpic(payload));
    const url = URL.createObjectURL(file);
    setUrls([...urls, url]);
    setFileData(file);
  };
  useEffect(() => {
    const file = fileData;

    if (file && uploadUrlTemp?.url) {
      dispatch(setAttachmentLoading(true));
      dispatch(putSignedUrl({
        url: uploadUrlTemp?.url,
        data: file
      }, dispatch))
    }

  }, [uploadUrlTemp]);
  useEffect(() => {
    dispatch(fetchTicketStatus());
    dispatch(fetchGroupEmployeData());
  }, []);
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (keywordsValue?.length < 10 && inputValue?.length <= 50) {
          setKeywordsValue((prev) => [...prev, createOption(inputValue)]);
          setInputValue("");
          event.preventDefault();
        } else if (inputValue?.length > 50) {
          showToastError("Please Enter maximum 50 characters");
        } else {
          showToastError("You can Enter maximum 10 keywords");
        }
        break
      default:
        break
    }
  };

  useEffect(() => {
    setKeywordsValue(
      ticketData?.AssignedPartNumber?.split(", ")?.map((item) => {
        return {
          label: item,
          value: item,
        }
      }));
  }, [ticketData?.AssignedPartNumber]);

  useEffect(() => {
    return () => {
      setAttachmentsList([]);
      dispatch(setDownloadFileTemp({}));
      dispatch(setUploadUrlTemp({}));
    }
  }, []);

  const handleAttachmentClick = (index) => {
    dispatch(downloadAttachment(attachmentsList[index]));
  }
  const deleteAttachment = (item, index) => {
    setUrls(urls?.splice(index));
    const temp = attachmentsList.filter(val => val != item);
    setAttachmentsList(temp);
    // dispatch(fetchAttachments(ticketId));
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <hr className="field_seperator" />
      <div className="form_col_edit">
        <div className={errors.title ? "form_error_edit_div" : "form_edit_div"}>
          <label>Title<span>*</span></label><br />
          <input
            type="text"
            {...register("title", { required: true })}
            className="edit_title"
            placeholder="Title"
            defaultValue={ticketData?.Title}
          />
          <br></br>
          {errors.title && <span className="required_text">Title is required</span>}
        </div>

        <div className={errors.workType ? "form_error_edit_div" : "form_edit_div"}>
          <label>Work Type <span>*</span></label><br />
          <Controller
            name="workType"
            control={control}
            rules={{ required: "Work Type is required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={workTypes}
                defaultValue={{ id: ticketData?.WorkTypeID, value: workTypeMap[ticketData?.WorkTypeID], label: workTypeMap[ticketData?.WorkTypeID] }}
                styles={DropdownControllers.controlStyles}
                placeholder="Select Work Type"
                className="edit_work_type"
              />
            )}
          />
          <br></br>
          {errors.workType && <span className="required_text">Work Type is required</span>}
        </div>
      </div>
      {/* <hr className="field_seperator" /> */}
      <div className="form_col_edit">
        <div className={errors.priority ? "form_error_div" : "form_edit_div"}>
          <label>Priority <span>*</span></label><br />
          <Controller
            name="priority"
            control={control}
            rules={{ required: "Priority is required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={priorities}
                styles={DropdownControllers.controlStyles}
                placeholder="Select Priority"
              />
            )}
          />
          <br></br>
          {errors.priority && <span className="required_text">Priority is required</span>}
        </div>

        <div className={errors.category ? "form_error_div" : "form_edit_div"}>
          <label>Category <span>*</span></label><br />
          <Controller
            name="category"
            rules={{ required: "Category is required" }}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={categories}
                styles={DropdownControllers.controlStyles}
                placeholder="Select Category"
              />
            )}
          />
          <br></br>
          {errors.category && <span className="required_text">Category is required</span>}
        </div>

      </div>
      <div className="form_col_edit">
        {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.isSample ? "form_error_div" : "form_edit_div"}>
          <label>Is Sample Available? <span>*</span></label>
          <Controller
            name="isSample"
            rules={{ required: "This is required" }}
            control={control}
            render={({ field }) => (
              <Select {...field} options={isSampleAvailable} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
            )}
          />
          <br></br>
          {errors.isSample && <span className="required_text">This is required</span>}
        </div>}

        {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.isProcess ? "form_error_div" : "form_edit_div"}>
          <label>Do we need to see their process? <span>*</span></label>
          <Controller
            name="isProcess"
            rules={{ required: "This is required" }}
            control={control}
            render={({ field }) => (
              <Select {...field} options={seeProcess} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
            )}
          />
          <br></br>
          {errors.isProcess && <span className="required_text">This is required</span>}
        </div>}
      </div>
      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.priority ? "form_error_div" : "form_div"}>
        <label>Process cable is used in <span>*</span></label><br></br>
        <div className={errors.processApplication ? "form_error_div" : "form_div"}>
          <Controller
            name="processApplication"
            control={control}
            render={({ field }) => (
              <MultiSelectDropdown
                field={field}
                rules={{ required: "This is required" }}
                options={processCableList}
                defaultValue={ticketData?.process_cable_used_in?.split(", ")?.map(value => ({
                  label: value,
                  value: value
                }))}
                // onSelect={handleSelectChange}
                displayValue="label"
              />
            )}
          />
          <br></br>
          {errors.processApplication && <span className="required_text">This is required</span>}        </div>
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.pullForceNeed ? "form_error_div" : "form_div"}>
        <label>Cable needs specific pull force to remove jacket? <span>*</span></label>
        <Controller
          name="pullForceNeed"
          rules={{ required: "This is required" }}
          defaultValue={{
            "label": "No",
            "value": "No"
          }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={pullForceNeeded} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
          )}
        />
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && pullForceNeed?.value == "Yes" && <div className={errors.newColumnPullForce ? "form_error_div" : "form_div"}>
        <label>How much Force? <span>*</span></label><br></br>
        <input
          name="newColumnPullForce"
          type="number"
          className="input_field_edit"
          {...register("newColumnPullForce", { required: true })}
        />
        <br></br>
        {errors.newColumnPullForce && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.automatedHandling ? "form_error_div" : "form_div"}>
        <label>Cable used in automated handling or stripping process? <span>*</span></label>
        <Controller
          name="automatedHandling"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select {...field} options={automatedHandling} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.automatedHandling && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && pullForceNeed?.value == "Yes" && <div className={errors.newColumnAutomated ? "form_error_div" : "form_div"}>
        <label>Automated Handling Details<span>*</span></label>
        <textarea
          name="newColumnAutomated"
          type="textarea"
          {...register("newColumnAutomated", { required: true })}
        />
        <br></br>
        {errors.newColumnAutomated && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.cableContactPoints ? "form_error_div" : "form_div"}>
        <label>Cable Contact points with external elements <span>*</span></label><br></br>
        <div className={errors.cableContactPoints ? "form_error_div" : "form_div"}>
          <Controller
            name="cableContactPoints"
            control={control}
            render={({ field }) => (
              <MultiSelectDropdown
                field={field}
                defaultValue={ticketData?.contact_points_with_elements?.split(", ")?.map((item) => { return { label: item, value: item } })}
                options={cableContactPointList}
                // onSelect={handleSelectChange}
                rules={{ required: "This is required" }}
                displayValue="label"
              />
            )}
          />
          <br></br>
          {errors.cableContactPoints && <span className="required_text">This is required</span>}        </div>
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.sunLightRes ? "form_error_div" : "form_div"}>
        <label>Sun light resistant plastic?  <span>*</span></label>
        <Controller
          name="sunLightRes"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select {...field} options={sunLightResistancePlastic} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.sunLightRes && <span className="required_text">This is required</span>}
      </div>}
      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.gammaRaysSterilize ? "form_error_div" : "form_div"}>
        <label>Gamma Ray sterilize? <span>*</span></label>
        <Controller
          name="gammaRaysSterilize"
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <Select {...field} options={gammaRays} styles={DropdownControllers.controlStyles} placeholder="Select Category" />
          )}
        />
        <br></br>
        {errors.gammaRaysSterilize && <span className="required_text">This is required</span>}
      </div>}

      {(categoryValue?.value === "OEM" || categoryValue?.value === "Valpo OEM") && <div className={errors.ulListingRequired ? "form_error_div" : "form_div"}>
        <label>UL Listing required? <span>*</span></label>
        <Controller
          name="ulListingRequired"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <MultiSelectDropdown
              field={field}
              options={UlListing}
              defaultValue={ticketData?.ul_listing_required?.map(value => ({
                id: value,
                label: UlListingMapping[value],
                value: UlListingMapping[value],
              }))} // Set the default value here
              onChange={(selected) => {
                field.onChange(selected); // Update the form state
              }}

              // onSelect={handleSelectChange}
              displayValue="label"
            />
          )}
        />
        <br></br>
        {errors.ulListingRequired && <span className="required_text">This is required</span>}
      </div>}


      <hr className="field_seperator" />
      <div className={errors.ticketStatus ? "form_error_div" : "form_div"}>
        <label>Ticket Status <span>*</span></label><br />
        <Controller
          name="ticketStatus"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={ticketStatus}
              styles={DropdownControllers.controlStyles}
              placeholder="Ticket Status"
            />
          )}
        />
        <br></br>
        {errors.ticketStatus && <span className="required_text">This is required</span>}
      </div>

      <div className={errors.description ? "form_error_div" : "form_div"}>
        <label>Description <span>*</span></label><br />
        <Controller
          name="description"
          rules={{ required: "This is required" }}
          control={control}
          render={({ field }) => (
            <RichText value={field.value} onChange={field.onChange}
            />
          )}
        />
        <br></br>
        {errors.description && <span className="required_text">This is required</span>}
      </div>

      <div className="form_div">
        <label>Customer Name <span>*</span></label><br />
        <Controller
          name="customerName"
          control={control}
          // rules={{ required: "This is required" }}
          render={({ field }) => (
            <Select
              field={field}
              options={customerNameList}
              onChange={(selected) => {
                field.onChange(selected); // Update the form state
              }}
              defaultValue={{ id: ticketData?.CustomerID, label: customerNameMap[ticketData?.CustomerID], value: customerNameMap[ticketData?.CustomerID] }}
              // defaultValue={convertStringToJsonArray(ticketData?.CustomerName)}
              styles={DropdownControllers.controlStyles}
              placeholder="Select Customer Name"
            />
          )}
        />
        {/* <br></br>
        {errors.customerName && <span className="required_text">This is required</span>} */}
      </div>
      <div className="form_sub_div">
        <div className={keywordsValue?.length == 0 ? "form_error_div" : "form_div"}>
          <label>Enter Assigned Part Numbers (Enter a value and press enter to select) <span>*</span></label>
          <Controller
            name="assignedPartNumber"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                inputValue={inputValue}
                styles={DropdownControllers.multiControl}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(selectedOptions) => {
                  field.onChange(selectedOptions);
                  setKeywordsValue(selectedOptions); // Optional: Your custom handler
                }}
                // onChange={(newValue) => setKeywordsValue(newValue)}
                onInputChange={(newValue) => {
                  if (isMobile) {
                    if (newValue != "") {
                      setInputValue(newValue);
                    } else if (inputValue.length == 1 && newValue == "") {
                      setInputValue(newValue);
                    }
                  } else {
                    setInputValue(newValue);
                  }
                }}
                onKeyDown={handleKeyDown}
                placeholder="Enter Assigned Part Numbers"
                value={keywordsValue}

              />
            )}
          />
          {/* <textarea
            {...register("assignedPartNumber", { required: true })}
            placeholder="Select Assigned Part Number"
          /> */}
          <br></br>
          {keywordsValue?.length == 0 && <span className="required_text">This is required</span>}
        </div>
        <div className="number_items">
          <div className="number_item">
            <div className={errors.assignedPartNumber ? "form_error_div" : "form_div"}>
              <label>Quote Part Required <span>*</span></label><br />
              <input
                name="quotePartRequired"
                className="edit_title"
                type="number"
                {...register("quotePartRequired", { required: true })}
              />
              <br></br>
              {errors.quotePartRequired && <span className="required_text">This is required</span>}
            </div>

            <div className={errors.orderPartRequired ? "form_error_div" : "form_div"}>
              <label>Order Part Required <span>*</span></label><br />
              <input
                name="orderPartRequired"
                type="number"
                className="edit_title"
                {...register("orderPartRequired", { required: true })}
              />
              <br></br>
              {errors.orderPartRequired && <span className="required_text">This is required</span>}
            </div>

          </div>

          <div className="number_item">
            <div className={errors.wordSpecsRequired ? "form_error_div" : "form_div"}>
              <label>Word Specs Required <span>*</span></label><br />
              <input
                name="wordSpecsRequired"
                type="number"
                className="edit_title"
                {...register("wordSpecsRequired", { required: true })}
              />
            </div>

            <div className={errors.cadDrawingsRequired ? "form_error_div" : "form_div"}>
              <label>CAD Drawings Required <span>*</span></label><br />
              <input
                name="cadDrawingsRequired"
                type="number"
                className="edit_title"
                {...register("cadDrawingsRequired", { required: true })}
              />
              <br></br>
              {errors.cadDrawingsRequired && <span className="required_text">This is required</span>}
            </div>
          </div>
        </div>
      </div>
      <div className={"form_div"}>
        <label>Footage</label><br />
        <input
          name="footage"
          type="number"
          className="input_field_edit"
          {...register("footage")}
          placeholder="Enter Footage"
        />
        <span className="number_input">ft</span>
      </div>

      <div className={"form_div"}>
        <label>Emails to Notify</label><br />
        <Controller
          name="emailsToNotify"
          control={control}
          render={({ field }) => (
            <MultiSelectDropdown
              field={field}
              options={groupEmployeData}
              defaultValue={ticketData?.emailsToNotify?.map(value => ({
                id: value?.GroupID ? value?.GroupID : value?.EmployeeID,
                label: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
                value: value?.GroupID ? assignedToGroupMap[value?.GroupID] : assignedToPersonMap[value?.EmployeeID],
                icon: value?.GroupID ? <GroupIcon /> : <PersonIcon />
              }))} // Set the default value here
              onChange={(selected) => {
                field.onChange(selected); // Update the form state
              }}
              // onSelect={handleSelectChange}
              displayValue="label"
            />
            // <MultiSelectDropdown
            //   field={field}
            //   options={groupEmployeData}
            //   onChange={(selectedOptions) => field.onChange(selectedOptions)}
            //   value={field.value}
            // />
          )}
        />
      </div>

      <div className="form_div">
        <label>Attachments</label><br />
        <input
          type="file"
          id="attachments"
          name="attachments"
          multiple
          {...register("attachments", {
            onChange: handleUploadImage
          })}
          // onChange={handleUploadImage}
          // onChange={(e) => register("attachments").onChange(e.target.files)}
          style={{ display: "none" }} // Hides the default input
        />
        <label
          htmlFor="attachments"
          className="custom_file_upload"
        >
          <div>
            <CloudDoneOutlinedIcon />
            <p className="upload_text">Choose a file or drag & drop <br></br>it here (50MB)</p>
            <div className="browse_btn">
              <span className="browse_btn_text">Browse File</span>
            </div>
          </div>
        </label>
      </div>
      {
        attachmentLoading &&
        <>
          <CircularProgress sx={{ marginTop: "30px", marginLeft: "20%" }} />
        </>
      }
      {
        attachmentsList?.map((item, index) => {
          return (
            <div className="selected_files">
              <div onClick={() => handleAttachmentClick(index)}>
                <img src={pdfIcon} alt="" width={isMobile ? 40 : 60} height={isMobile ? 50 : 70} className="upload_document" />
              </div>
              <div className="selected_files_text" onClick={() => handleAttachmentClick(index)}>
                <div>
                  <span className="file_details">{item}</span>
                  <br></br>
                  {/* <CheckCircleRoundedIcon sx={{ color: "white", height: "15px", width: "15px" }} />  */}
                  {/* <span className="file_details">Completed</span> */}
                </div>
              </div>
              <div className="clear_icon">
                <ClearOutlinedIcon sx={{ color: "white", right: "0px" }} onClick={() => deleteAttachment(item, index)} />
              </div>
            </div>
          );
        })
      }

      <hr className="field_seperator" />

      <div className="form_div">
        <div className="btn_div">
          <button
            id="cancel_btn"
            type="button"
            onClick={() => { reset(initialValues); dispatch(setOpenRevisionModal(false)); navigate("/"); }}
          >
            Cancel
          </button>
          <button
            id="edit_ticket"
            type="submit"
          >
            Create Revision
          </button>
        </div>
      </div>
    </form>
  );
};

export default RevisionTicketForm;
