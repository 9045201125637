import React, { useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
// import "@ag-grid-community/styles/ag-theme-alpine.css"; 

const SimpleGrid = () => {
  const [rowData] = useState([
    { TicketID: 1, Title: "Fix Bug", PriorityID: "Urgent" },
    { TicketID: 2, Title: "Add Feature", PriorityID: "Normal" },
    { TicketID: 3, Title: "Improve UI", PriorityID: "Normal" },
  ]);

  const columnDefs = [
    { field: "TicketID", filter: "agNumberColumnFilter" },
    { field: "Title", filter: "agTextColumnFilter" },
    { field: "PriorityID", filter: "agSetColumnFilter" },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
      />
    </div>
  );
};

export default SimpleGrid;
