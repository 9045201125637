import toast from "react-hot-toast";
export const showToastSuccess = (message) => {
  toast.success(`${message}`, {
    style: {
      backgroundColor: "#d1fae5",
      color: "green",
      zIndex: 9999, 
    },
  });
};
export const showToastError = (message) => {
  toast.error(`${message}`, {
    style: {
      backgroundColor: "red",
      zIndex: 9999, 
    },
  });
};
