export default function convertIntoDateHour(dateString) {
    const date = new Date(dateString);
  
    date.setHours(12, 34, 0, 0);

// Format to YYYY-MM-DD HH:MM
const formattedTime = date.toISOString().slice(0, 10) + " " + date.toTimeString().slice(0, 5);
  
    // Combine components into the desired format
    return formattedTime;
  }
  