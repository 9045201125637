const mapFields = (title) => {
    switch (title) {
        case "WorkTypeID":
            return "Work Type";
        case "Title":
            return "Title";
        case "AssignedToPersonID":
            return "Assigned To";
        case "AssignedToGroupID":
            return "Assigned To";
        case "PriorityID":
            return "Priority";
        case "CategoryID":
            return "Category";
        case "TicketStatusID":
            return "Ticket Status";
        case "ReasonForNoQuoteID":
            return "Reason For Quote";
        case "MistakeCodeID":
            return "Mistake Code";
        case "Description":
            return "Description";
        case "CustomerID":
            return "Customer";
        case "contact_points_with_elements":
            return "Contact Points";
        case "AssignedPartNumber":
            return "Assigned Part Number";
        case "QuotePartRequired":
            return "Quote Part";
        case "WordSpecsRequired":
            return "Word Specs";
        case "OrderPartRequired":
            return "Order Part";
        case "CADDrawingsRequired":
            return "CAD Drawings";
        case "ReasonForLate":
            return "Reason For Late";
        case "Notes":
            return "Notes";
        case "is_sample_available":
            return "Is Sample Available";
        case "can_get_sample":
            return "Can Get Sample";
        case "need_to_see_process":
            return "Need to See Process";
        case "process_cable_used_in":
            return "Process Cable Used In";
        case "automated_handling_process":
            return "Automated Handling Process";
        case "specific_pull_force_required":
            return "Specific Pull Force Required";
        case "automated_handling_details":
            return "Automated Handling Details";
        case "pull_force_value":
            return "Pull Force Value";
        case "sun_lite_resistant_plastic":
            return "Sun Light Resistance Plastic";
        case "gamma_ray_sterilize":
            return "Gamma Ray Sterilize";
        case "ul_listing_required":
            return "UL Listing Required";
        default:
            break;
    }
}
export default mapFields;